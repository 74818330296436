import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { CrewListComponent } from './crew/crew-list/crew-list.component';
import { CrewMediaComponent } from './crew/crew-media/crew-media.component';
import { ProfileComponent } from './crew/profile/profile.component';
import { UserResolve } from './user.resolve';
import { excludePath } from 'src/app/app-routing.module';

const routes: Routes = [
  {
    path:'profile',
    component:ProfileComponent
  },
  {
    path:'view-profile/id/:id',
    component:ProfileComponent,
    resolve: {
      detail: UserResolve,
    }
  },
  {
    path: 'profile/:userName',
    pathMatch: 'full',
    redirectTo: ':userName'
  },
  // {
  //   path:'profile/:userName',
  //   component:ProfileComponent,
  //   resolve: {
  //     detail: UserResolve,
  //   }
  // },
  
  {
    // path:':userName',
    matcher: excludePath,
    component:ProfileComponent,
    resolve: {
      detail: UserResolve,
    }
  },
  {
    path:'crew-list',
    component:CrewListComponent
  },
  // {
  //   path:'crew-list/:searchStr',
  //   component:CrewListComponent
  // },
  {
    path:'crew-media/:id/:mediaType',
    component:CrewMediaComponent
  },
  {
    path:'crew-media',
    component:CrewMediaComponent
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule { }
