import { NgModule } from '@angular/core';
import { BrowserModule, Meta , BrowserTransferStateModule} from '@angular/platform-browser';
import { CommonModule } from '@angular/common';

import { AppComponent } from './app.component';
import { DefaultLayoutComponent } from './container/default-layout/default-layout.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module'; 
import { InputTextModule } from 'primeng/inputtext';
import { StyleClassModule } from 'primeng/styleclass';


import {CoreModule } from './core/core.module';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';

import { SharedModule } from './shared/shared.module';
import { commonModule } from './modules/common/common.module';
import { CommonRoutingModule } from './modules/common/common-routing.module';
import { UsersModule } from './modules/users/users.module';
import { UsersRoutingModule } from './modules/users/users-routing.module';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { CrewResumesInterceptor } from './core/services/crew-resumes.interceptor';
import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';

import { YouTubePlayerModule } from "@angular/youtube-player";

@NgModule({
  declarations: [
    AppComponent,
    DefaultLayoutComponent,
  ],
  imports: [
    YouTubePlayerModule,
    GooglePlaceModule,
    UsersRoutingModule,
    CommonModule,
    CommonRoutingModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserTransferStateModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    UsersModule,
    SharedModule,
    HttpClientModule,
    CoreModule,
    StyleClassModule,
    InputTextModule,
    commonModule

  ],
  providers: [Meta,
    {
    provide: HTTP_INTERCEPTORS,
    useClass:CrewResumesInterceptor,
    multi: true
    },
    {provide: LocationStrategy, useClass: PathLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
