import { Component, OnInit } from '@angular/core';
import {MessageService} from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { CommonService } from '../common.service';
import { SeoService } from 'src/app/seo.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-link-resources',
  templateUrl: './link-resources.component.html',
  styleUrls: ['./link-resources.component.scss']
})
export class LinkResourcesComponent implements OnInit {
  links:any = []
  totalRecords: any
  pageLoader = false;
  resourceList: any = []
  
  /**
  * @constructor
  * @param {ConfirmationService} confirmationService - Confirmation services is required to display it by calling confirm method.
  * @param {CommonService} service  - The service for call API.
  * @param {MessageService} messageService - Message is used to display alerts inline.
  */
  constructor(
    private confirmationService:ConfirmationService,
    private messageService: MessageService,
    private service: CommonService,
    private seo: SeoService,
    private router:Router,
  ) { }

  ngOnInit(): void {
    this.getResourcesList()
    this.getLinkList()
    this.updateTags()
  }


  updateTags(){
    this.seo.updateCanonicalUrl(environment.baseUrl + this.router.url)
    this.seo.updateTitle('CrewResumes - Link And Resources')
    this.seo.updateTags([
      { name: 'description', content: 'The largest media and entertainment cast and crew database in the world, developed by industry professionals who have experienced a need to prep, find, replace, or hire talent.' },
      { property: 'og:url', content: environment.baseUrl + this.router.url},
      { property: 'og:title', content: 'CrewResumes - Link And Resources' },
      { property: 'og:description', content: 'The largest media and entertainment cast and crew database in the world, developed by industry professionals who have experienced a need to prep, find, replace, or hire talent.' },
      { property: 'og:image', content: environment.logoUrl },
    ]);
   }
  /**
   * Get all item by type list in data table
   * @method getResourcesList
   * @returns { Array } Array
   */
  getResourcesList() {
    this.resourceList = []
    this.pageLoader = true;
    this.service.getResourceList().subscribe((data: any) => {
      this.pageLoader = false;
      this.resourceList = data
      // console.log(this.resourceList)
    },(error : any) =>{
      this.pageLoader = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.error.message });
    })
  }

    /**
   * Get all link list
   * @method getLinkList
   * @returns {Array} Array
  */
    getLinkList() {
      this.links = []
      this.pageLoader = true;
      this.service.getlinkList().subscribe((data: any) => {
        this.pageLoader = false;
        this.links = data
        // console.log(this.links)
      },(error : any) =>{
        this.pageLoader = false;
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.error.message });
      })
    }
}
