import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CommonRoutingModule } from './common-routing.module';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { DataPrivacyComponent } from './data-privacy/data-privacy.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { FaqComponent } from './faq/faq.component';

import { HomeComponent } from './home/home.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { LegalInformationComponent } from './legal-information/legal-information.component';
import { NonLoginFooterComponent } from './non-login-footer/non-login-footer.component';
import { NonLoginHeaderComponent } from './non-login-header/non-login-header.component';
import { P404Component } from './p404/p404.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CookieComponent } from './cookie/cookie.component';
import { ReturnPolicyComponent } from './return-policy/return-policy.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { LinkResourcesComponent } from './link-resources/link-resources.component';

@NgModule({
  declarations: [
    AboutUsComponent,
    ContactUsComponent,
    DataPrivacyComponent,
    HeaderComponent,
    FooterComponent,
    FaqComponent,
    HomeComponent,
    HowItWorksComponent,
    LegalInformationComponent,
    NonLoginFooterComponent,
    NonLoginHeaderComponent,
    P404Component,
    TermsOfServiceComponent,
    CookieComponent,
    ReturnPolicyComponent,
    DisclaimerComponent,
    LinkResourcesComponent
  ],
  imports: [
    CommonModule,
    CommonRoutingModule,
    SharedModule,
    
  ],
  exports:[
    HeaderComponent,
    FooterComponent,
    NonLoginFooterComponent,
    NonLoginHeaderComponent,
    HomeComponent,
    P404Component,
  ]
})
export class commonModule { }
