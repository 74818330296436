<div class="">
    <div class="flex flex-wrap">
        <div class="bg-no-repeat bg-cover main-banner" style="background: url('assets/images/homebanner.jpg'); width:100%;">
            <div class="px-3 lg:px-6 pt-8 pb-6 text-center">
                <div class="text-4xl md:5xl text-white mb-5 font-bold"></div>
                <h3 class="line-height-3 mb-5 p-0 text-white text-2xl md:text-5xl mt-8">Find the perfect crew members <br class="hidden md:block">for any project all over the world</h3>
                <button (click)="goList()" pButton pRipple label="Search Cast And Crew" class="p-button-primary p-button-lg mb-6"></button>
            </div>
        </div>
    </div>
</div>

<!-- Gradient background -->
 <div class="surface-ground px-4 py-7 md:px-6">
    <div class="container">
        <div class="text-center text-900 text-2xl lg:px-8 mb-5"> <span class="text-primary-500">Crew Resumes </span> is the largest media and entertainment cast and crew database in the world, developed by industry professionals who have experienced a need to prep, find, replace, or hire talent.</div>
        <div class="grid text-center">
            <div class="lg:col-4 box p-3">
                <div class="surface-card border-round shadow-2" style="height: 100%; border-radius: 20px !important;">
                    <img src="assets/images/platform.jpg" height="300"  alt="Image" class="block w-full border_roundtop" style="object-fit: cover;">
                    <div class="p-4">
                    <h3 class="block mb-3 font-normal mt-0 text-xl">Choose your Platform</h3>
                    <div class="text-sm line-height-3 text-700 text-5-line">Motion Picture, Short Film, Television, Commercial, Infomercial, 
                        Documentary, Reality, Animation, Audience Music & Variety, Live Action, Live to Tape, Music Video, Radio, 
                        Podcast, Sports Multi Camera, Web Series, Social Media, YouTube, and Gaming.</div>
                    <div class="flex mt-3 justify-content-center">
                        <button class="p-button-outlined" pButton label="Read More" (click)="showDialog()"></button>
                    </div>
                    </div>
                </div>
                </div>
                <div class="lg:col-4 box p-3">
                <div class="surface-card border-round shadow-2" style="height: 100%; border-radius: 20px !important;">
                    <img src="assets/images/department.jpg" height="300" alt="Image" class="block w-full border_roundtop" style="object-fit: cover;">
                    <div class="p-4">
                    <h3 class="block mb-3 font-normal mt-0 text-xl">Choose your Department</h3>
                    <div class="text-sm line-height-3 text-700 text-5-line">Actors, Agents & Representatives, Art Department, 
                        Camera Department, Casting Directors, Digital, Directors, Editors, Gaming, Grip & Electric, Hair & Makeup, 
                        Military World, Models, Podcast, Producers, Production Office Support, Radio, Social Media, 
                        Sound Department & Music, Special Effects & Visual Effects, Talent Managers, TikTok Talent, Wardrobe & Costume, 
                        Writers, YouTube.</div>
                    <div class="flex mt-3 justify-content-center">
                        <button class="p-button-outlined" pButton label="Read More" (click)="showDialog1()"></button>
                    </div>
                    </div>
                </div>
                </div>
                <div class="lg:col-4 box p-3">
                <div class="surface-card border-round shadow-2" style="height: 100%; border-radius: 20px !important;">
                    <img src="assets/images/jobtitle.jpg" height="300"  alt="Image" class="block w-full border_roundtop" style="object-fit: cover;">
                    <div class="p-4">
                    <h3 class="block mb-3 font-normal mt-0 text-xl">Choose your Job Title</h3>
                    <div class="text-sm line-height-3 text-700 text-5-line">Choose your Job Title. Our machine will guide you through 
                        the steps to build your profile on our world-wide database and create a webpage you can share 
                        with potential agents or producers.</div>
                    <div class="flex mt-3 justify-content-center">
                        <button class="p-button-outlined" pButton label="Read More" (click)="showDialog2()"></button>
                    </div>
                    </div>
                </div>
                </div>
            <div class="lg:col-6 box p-3">
                <div class="surface-card border-round shadow-2" style="height: 100%; border-radius: 20px !important;">
                    <img src="assets/images/aboutpam.jpg" height="300"  alt="Image" class="block w-full border_roundtop large-image" style="object-fit: cover;">
                    <div class="p-4">
                    <h3 class="block mb-3 font-normal mt-0 text-xl">Producers, Directors, Networks, Studios</h3>
                    <div class="text-sm line-height-3 text-700 text-5-line">All producers looking to hire Cast or Crew can search Crew Resumes for free. 
                        Designed to eliminate the chaos, learn everything you need to know about a possible hire in one place. 
                        Call or email the agents, managers, attorneys, or publicists.  
                        View actors reels, photos, listen to voice over or see who has equipment, camera gear and kits available. 
                        Read a personal bio or go straight to the social media pages from one profile page. 
                        There are over 400 job titles to search.</div>
                    <div class="flex mt-3 justify-content-center">
                        <button class="p-button-outlined" pButton label="Read More" (click)="showDialog3()"></button>
                    </div>
                    </div>
                </div>
                </div>
                <div class="lg:col-6 box p-3">
                <div class="surface-card border-round shadow-2" style="height: 100%; border-radius: 20px !important;">
                    <img src="assets/images/usacanada.jpg" height="300" alt="Image" class="block w-full border_roundtop large-image" style="object-fit: cover;">
                    <div class="p-4">
                    <h3 class="block mb-3 font-normal mt-0 text-xl">USA & Canada Cast and Crew</h3>
                    <div class="text-sm line-height-3 text-700 text-5-line">Pam Bouvier is a casting director who has cast in all media and entertainment platforms for over twenty-five years. Pam is the production horse behind this wheel spinning throughout all film commission offices, studios, networks, production offices, and over 200 English speaking countries.  Cast and Crew members have a professional webpage with no sign up needed to view. The motivation is to represent the crew of the world in over 400 job titles. The crew page is designed for fast viewing and easy access from a hiring producers point of view. 
                        Use your new profile page as a webpage for only $15.00 a month. 
                        No one will need to sign in to view you. 
                        Use your www.crewresumes.com/yournamehere as  you wish.  
                        Be a part of the largest crew database in the world!</div>
                    <div class="flex mt-3 justify-content-center">
                        <button class="p-button-outlined" pButton label="Read More" (click)="showDialog4()"></button>
                    </div>
                    </div>
                </div>
                </div>
                <div class="col-12  box p-3">
                    <div class="surface-card border-round p-4 shadow-2" 
                    style="border-radius: 20px !important; background-color: #011128 !important; color:#fff;">
                        <h3 class="block text-black-600 my-0 text-xl font-normal" 
                        style="text-align: center;">International Cast and Crew are free to sign up in Crew Resumes!</h3>
                    </div>
                </div>

        <div class="col-12 lg:col-6 p-3">
            <div class="shadow-2 border_round h-full surface-card">
                <img src="assets/images/aboutcrewresume.jpg" height="300" alt="Image" class="block w-full border_roundtop large-image" style="object-fit: cover; object-position: top;">
                <div class="p-4">
                    <h3 class="block mb-3 font-normal mt-0 text-xl">About Crew Resumes</h3>
                    <div class="text-sm line-height-3 text-700">Crew Resumes is the largest world-wide Cast 
                        and Crew database developed with over four hundred job titles designed for 
                        the hiring producer to find talent quickly. Time is money and people in production move fast...</div>
                    <div class="flex mt-3 justify-content-center">
                       <button  pButton class="p-button-outlined" label="Read More" (click)="readMoreAboutPam()"></button>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 lg:col-6 p-3">
            <div class="shadow-2 border_round h-full surface-card">
                <img src="assets/images/camgirl.jpg" height="300"  alt="Image" class="block w-full border_roundtop large-image" style="object-fit: cover; object-position: top;">
                <div class="p-4 flex flex-column">
                    <h3 class="block mb-3 font-normal mt-0 text-xl">About Pam</h3>
                    <div class="text-sm line-height-3 text-700"> Pam Bouvier has been an industry professional in 
                        the media and entertainment industry for over 25 years.  Her company in Los Angeles, 
                        California has cast hundred's  of commercials,  infomercials, television network...</div>
                    <div class="flex mt-3 justify-content-center">
                        <button class="p-button-outlined" pButton label="Read More" (click)="readMoreAboutPam()"></button>
                    </div>
                </div>
            </div>
        </div>
       
        <!-- <div class="col-12 lg:col-4 p-3 mb">
            <div class="shadow-2 border_round h-full surface-card">
                <img src="assets/logo/gordon-cowie-A6mvs7korr0-unsplash.jpg" height="200"  alt="Image" class="block w-full border_roundtop" style="object-fit: cover;">
                <div class="p-4">
                    <h3 class="block mb-3 font-normal mt-0 text-xl">How Crew Resumes Started</h3>
                    <div class="text-sm line-height-3 text-700">During the pandemic of 2020 I reached out to 
                        over one hundred film commission offices all over the world to discuss a portal where cast 
                        and crew can be found internationally, 
                        and producers and directors can get an idea...</div>
                    <div class="flex mt-3 justify-content-center">
                            <button class="p-button-outlined" pButton label="Read More" (click)="readMoreAboutPam()"></button>
                    </div>
                </div>
            </div>
        </div> -->
        </div>
    </div>
    </div>
<!-- for toast msg alert -->
<p-toast position="top-right"></p-toast>
<!-- for dialog box -->
<p-dialog header="Choose your Platform" [(visible)]="display1" [style]="{width: '50vw'}" [breakpoints]="{'960px': '75vw', '640px': '90vw'}" [draggable]="false" [resizable]="false">
    <div class="border-top-2 surface-border mb-2"></div>
    <div>
       <p>Motion Picture, Short Film, Television, Commercial, Infomercial, 
        Documentary, Reality, Animation, Audience Music & Variety, Live Action, Live to Tape, Music Video, Radio, 
        Podcast, Sports Multi Camera, Web Series, Social Media, YouTube, and Gaming.</p>
    </div>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="display1=false" class="p-button-secondery" label="Ok" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>
<!-- for dialog box -->
<p-dialog header="Choose your Department" [(visible)]="display2" [style]="{width: '50vw'}" [breakpoints]="{'960px': '75vw', '640px': '90vw'}" [draggable]="false" [resizable]="false">
    <div class="border-top-2 surface-border mb-2"></div>
    <div>
       <p>Actors, Agents & Representatives, Art Department, 
        Camera Department, Casting Directors, Digital, Directors, Editors, Gaming, Grip & Electric, Hair & Makeup, 
        Military World, Models, Podcast, Producers, Production Office Support, Radio, Social Media, 
        Sound Department & Music, Special Effects & Visual Effects, Talent Managers, TikTok Talent, Wardrobe & Costume, 
        Writers, YouTube.</p>
    </div>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="display2=false" class="p-button-secondery" label="Ok" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>
<!-- for dialog box -->
<p-dialog header="Choose your Job Title" [(visible)]="display3" [style]="{width: '50vw'}" [breakpoints]="{'960px': '75vw', '640px': '90vw'}" [draggable]="false" [resizable]="false">
    <div class="border-top-2 surface-border mb-2"></div>
    <div>
       <p>Choose your Job Title. Our machine will guide you through 
        the steps to build your profile on our world-wide database and create a webpage you can share 
        with potential agents or producers.</p>
    </div>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="display3=false" class="p-button-secondery" label="Ok" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>
<!-- for dialog box -->
<p-dialog header="Producers, Directors, Networks, Studios" [(visible)]="display4" [style]="{width: '50vw'}" [breakpoints]="{'960px': '75vw', '640px': '90vw'}" [draggable]="false" [resizable]="false">
    <div class="border-top-2 surface-border mb-2"></div>
    <div>
       <p>All producers looking to hire Cast or Crew can search Crew Resumes for free. 
        Designed to eliminate the chaos, learn everything you need to know about a possible hire in one place. 
        Call or email the agents, managers, attorneys, or publicists.  
        View actors reels, photos, listen to voice over or see who has equipment, camera gear and kits available. 
        Read a personal bio or go straight to the social media pages from one profile page. 
        There are over 400 job titles to search.</p>
    </div>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="display4=false" class="p-button-secondery" label="Ok" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>
<!-- for dialog box -->
<p-dialog header="USA & Canada Cast and Crew" [(visible)]="display5" [style]="{width: '50vw'}" [breakpoints]="{'960px': '75vw', '640px': '90vw'}" [draggable]="false" [resizable]="false">
    <div class="border-top-2 surface-border mb-2"></div>
    <div>
       <p>Pam Bouvier is a casting director who has cast in all media and entertainment platforms for over twenty-five years. Pam is the production horse behind this wheel spinning throughout all film commission offices, studios, networks, production offices, and over 200 English speaking countries.  Cast and Crew members have a professional webpage with no sign up needed to view. The motivation is to represent the crew of the world in over 400 job titles. The crew page is designed for fast viewing and easy access from a hiring producers point of view. 
        Use your new profile page as a webpage for only $15.00 a month. 
        No one will need to sign in to view you. 
        Use your www.crewresumes.com/yournamehere as  you wish.  
        Be a part of the largest crew database in the world!</p>
    </div>
    <ng-template pTemplate="footer">
        <p-button icon="pi pi-check" (click)="display5=false" class="p-button-secondery" label="Ok" styleClass="p-button-text"></p-button>
    </ng-template>
</p-dialog>