import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { SeoService } from 'src/app/seo.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  /**
    * @constructor
    * @param {SeoService} seo - The seo service for call API.
    * @param {Router} router - For getting router details.
  */
  constructor(
    private seo: SeoService,
    private router:Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit(): void {
    this.updateTags()
  }
  
  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
    }
  }
  //update the tags
   updateTags(){
    this.seo.updateCanonicalUrl(environment.baseUrl + this.router.url)
    this.seo.updateTitle('Discover FAQs | The Ultimate Crew Resume Database')
    this.seo.updateTags([
      { name: 'description', content: "Explore FAQs on the world's largest media & entertainment crew database." },
      { property: 'og:url', content: environment.baseUrl + this.router.url},
      { property: 'og:title', content: 'Discover FAQs | The Ultimate Crew Resume Database' },
      { property: 'og:description', content: "Explore FAQs on the world's largest media & entertainment crew database." },
      { property: 'og:image', content: environment.logoUrl },
    ]);
  }
  //  updateTags(){
  //   this.seo.updateCanonicalUrl(environment.baseUrl + this.router.url)
  //   this.seo.updateTitle('Crew Resumes - Media And Entertainment Cast And Crew Database')
  //   this.seo.updateTags([
  //     { name: 'description', content: 'The largest media and entertainment cast and crew database in the world, developed by industry professionals who have experienced a need to prep, find, replace, or hire talent.' },
  //     { property: 'og:url', content: environment.baseUrl + this.router.url},
  //     { property: 'og:title', content: 'CrewResumes - Media And Entertainment Cast And Crew Database' },
  //     { property: 'og:description', content: 'The largest media and entertainment cast and crew database in the world, developed by industry professionals who have experienced a need to prep, find, replace, or hire talent.' },
  //     { property: 'og:image', content: environment.logoUrl },
  //   ]);
  // }
   
}
