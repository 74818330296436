import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {MessageService} from 'primeng/api';
import { CommonService } from '../common.service';
import { SeoService } from 'src/app/seo.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
  contactUs: FormGroup;
  submitted1:boolean = false;

  /**
  * @constructor
  * @param {CommonService} commonservice - The commonsservice service for call API.
  * @param {SeoService} seo - The seo service for call API.
  * @param {MessageService} messageService - Message is used to display alerts inline.
  * @param {Router} router - For getting router details.
  * @param {FormBuilder} formBuilder - FormBuilder is the helper API to build forms in angular, it provides shortcuts to create the instance of FormControl.
  */
  constructor(
    private formBuilder: FormBuilder,
    private messageService: MessageService, 
    private commonservice:CommonService,
    private router:Router,
    private seo: SeoService,
    @Inject(PLATFORM_ID) private platformId: Object
    ) {
      // Form declaration
      this.contactUs = this.formBuilder.group({
        firstName: new FormControl('',Validators.required),
        lastName: new FormControl('',Validators.required),
        email : new FormControl('',Validators.required),
        phone : new FormControl('',Validators.required),
        message: new FormControl('',Validators.required),
        
      })
     }

     ngOnInit(): void {
      this.updateTags()
    }
    //update the tag
     updateTags(){
      this.seo.updateCanonicalUrl(environment.baseUrl + this.router.url)
      this.seo.updateTitle('Contact Us: Reach Out for Crew Resume Assistance')
      this.seo.updateTags([
        { name: 'description', content: 'Get in touch with us for inquiries about the largest media & entertainment crew database.' },
        { property: 'og:url', content: environment.baseUrl + this.router.url},
        { property: 'og:title', content: 'Contact Us: Reach Out for Crew Resume Assistance'},
        { property: 'og:description', content: 'Get in touch with us for inquiries about the largest media & entertainment crew database.' },
        { property: 'og:image', content: environment.logoUrl },
      ]);
     }
    //  updateTags(){
    //   this.seo.updateCanonicalUrl(environment.baseUrl + this.router.url)
    //   this.seo.updateTitle('Crew Resumes - Media And Entertainment Cast And Crew Database')
    //   this.seo.updateTags([
    //     { name: 'description', content: 'The largest media and entertainment cast and crew database in the world, developed by industry professionals who have experienced a need to prep, find, replace, or hire talent.' },
    //     { property: 'og:url', content: environment.baseUrl + this.router.url},
    //     { property: 'og:title', content: 'CrewResumes - Media And Entertainment Cast And Crew Database' },
    //     { property: 'og:description', content: 'The largest media and entertainment cast and crew database in the world, developed by industry professionals who have experienced a need to prep, find, replace, or hire talent.' },
    //     { property: 'og:image', content: environment.logoUrl },
    //   ]);
    //  }
  

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
    }
  }

  // for form controls
  get f() { return this.contactUs.controls; }
 
   /**
   * submit conatct us form
   * @method submit
   * @returns { Object } Object
   */
  submit(){
    this.submitted1 = true;
    if(this.contactUs.invalid){
      this.messageService.add({severity:'error', 
      summary: 'Error', 
      detail: 'Please fill up all the required fields'});
      return
    }
    this.commonservice.contact(this.contactUs.value).subscribe((x)=>{
      this.contactUs.reset()
      this.submitted1 = false;
      this.messageService.add({ severity: 'success', 
      summary: 'Success', 
      detail: 'Thank you for contacting us. We will get in touch with you shortly.' });
    },(error : any) => {
        this.messageService.add({ severity: 'error', 
        summary: 'Error', 
        detail: error.error.error.message });
      }
    )
 
  }
}
