import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';
import {PaginatorModule} from 'primeng/paginator';
import {ToastModule} from 'primeng/toast';
import {AccordionModule} from 'primeng/accordion';
import { BadgeModule } from 'primeng/badge';
import { FileUploadModule } from 'primeng/fileupload'; 
import { AvatarModule } from 'primeng/avatar';
import { AvatarGroupModule } from 'primeng/avatargroup';
import { TagModule } from 'primeng/tag';
import { MenuModule } from 'primeng/menu';
import { InputTextareaModule } from 'primeng/inputtextarea';
import {MultiSelectModule} from 'primeng/multiselect';
import { InputTextModule } from 'primeng/inputtext';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputSwitchModule } from 'primeng/inputswitch';
import { CheckboxModule } from 'primeng/checkbox';
import { CarouselModule } from 'primeng/carousel';
import { DividerModule } from 'primeng/divider';
import { ReactiveFormsModule , FormsModule } from '@angular/forms'
import {MessageService} from 'primeng/api';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import {ImageModule} from 'primeng/image';
import { StyleClassModule } from 'primeng/styleclass';
import { DropdownModule } from 'primeng/dropdown';

import { GalleriaModule } from 'primeng/galleria';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService} from 'primeng/api';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import {TabViewModule} from 'primeng/tabview';

@NgModule({
  declarations: [],
  imports: [
    TabViewModule,
    ImageModule,
    ProgressSpinnerModule,
    GooglePlaceModule,
    ConfirmDialogModule,
    GalleriaModule,
    DropdownModule ,
    StyleClassModule,
    FormsModule,
    ReactiveFormsModule,
    DividerModule,
    CarouselModule,
    CheckboxModule,
    InputSwitchModule,
    RadioButtonModule,
    RippleModule,
    ButtonModule ,
    SidebarModule ,
    InputTextModule,
    MultiSelectModule,
    CommonModule,
    PaginatorModule,
    ToastModule,
    AccordionModule,
    BadgeModule,
    FileUploadModule,
    AvatarModule,
    AvatarGroupModule,
    TagModule,
    MenuModule,
    InputTextareaModule,
    TableModule,
    DialogModule,    
  ],
  exports:[
    TabViewModule,
    ImageModule,
    ProgressSpinnerModule,
    GooglePlaceModule,
    ConfirmDialogModule,
    GalleriaModule,
    DropdownModule ,
    StyleClassModule,
    FormsModule,
    ReactiveFormsModule,
    DividerModule,
    CarouselModule,
    CheckboxModule,
    InputSwitchModule,
    RadioButtonModule,
    RippleModule,
    ButtonModule ,
    SidebarModule ,
    InputTextModule,
    MultiSelectModule,
    CommonModule,
    PaginatorModule,
    ToastModule,
    AccordionModule,
    BadgeModule,
    FileUploadModule,
    AvatarModule,
    AvatarGroupModule,
    TagModule,
    MenuModule,
    InputTextareaModule ,
    TableModule,
    DialogModule,
    
  ],
  providers:[
    MessageService,ConfirmationService
  ]
})
export class SharedModule { }
