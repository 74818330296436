import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { UsersRoutingModule } from './users-routing.module';
import { ProfileComponent } from './crew/profile/profile.component';
import { CrewListComponent } from './crew/crew-list/crew-list.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { CrewMediaComponent } from './crew/crew-media/crew-media.component';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { UserResolve } from './user.resolve';


@NgModule({
  declarations: [
    ProfileComponent,
    CrewListComponent,
    CrewMediaComponent
  ],
  imports: [
    CommonModule,
    UsersRoutingModule,
    SharedModule,
    YouTubePlayerModule
  ],
  providers: []
})
export class UsersModule { }
