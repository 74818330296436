import { NgModule } from '@angular/core';
import { RouterModule, Routes, UrlSegment } from '@angular/router';
import { AboutUsComponent } from './modules/common/about-us/about-us.component';
import { HomeComponent } from './modules/common/home/home.component';
import { P404Component } from './modules/common/p404/p404.component';
import { DefaultLayoutComponent } from './container';
import { ProfileComponent } from './modules/users/crew/profile/profile.component';
import { CrewListComponent } from './modules/users/crew/crew-list/crew-list.component';
const routes: Routes = [
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      {
        path: 'common', loadChildren: () =>
          import('./modules/common/common.module').then((m) => m.commonModule),
      },
      {
        path: 'users',
        loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule)
      },
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        // path: ':userName',
        matcher: excludePath,
        component: ProfileComponent
      },
      {
        path: 'crew-list',
        component: CrewListComponent
      }


    ]
  },
  {
    path: '**',
    component: P404Component
  },

];


export function excludePath(url: UrlSegment[]): any {
  let pathMatch = null;
  console.log(url)
  if(url.length === 1){
    let path = url[0].path;
    if(!excludePathArr.includes(path)){
      pathMatch = {
        consumed: url,
        posParams: {
          userName: new UrlSegment(path, {})
        }
      }
    }
  }
  return pathMatch;
}

export const excludePathArr = [
  'term-of-service',
  'crew-list',
  '404',
  '**',
  'crew-media',
  'about-us',
  'contact-us',
  'data-privacy',
  'faq',
  'how-it-works',
  'legal-information',
  'term-of-service',
  'cookie',
  'disclaimer',
  'return-policy',
  'link-resources',
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledBlocking'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
