<div class="surface-ground text-800 px-4 py-6 md:px-6">
    <!-- faq accordion used-->
    <div class="grid">
        <div class="col-12 md:col-3">
            <span class="block font-bold text-3xl mb-3">Frequently Asked Questions</span>
            <div class="text-700 line-height-3">Please email us at <a href="mailto:support@crewresumes.com">support@crewresumes.com</a> for any questions you may have.</div>
        </div>
        <div class="col-12 md:col-9 md:pl-5 faq-accordion">
            <p-accordion [activeIndex]="0">
                <p-accordionTab header="Is this a job board Pam?">
                    <div class="line-height-3">
                        No. This is designed to be the fastest way to connect producers to talent without all the hoopla. A producer can search for any media platform, department and or job title anywhere in the world. </div>
                </p-accordionTab>
                <p-accordionTab header="Do you come between a crew member and the hiring producer?">
                    <div class="line-height-3">Absolutely not. When crewing up talent for a movie recently, I found it extremely difficult to get to people fast. The profile page is designed for hiring producers to contact you. It will continue to update according to producer needs, so you don’t have to.</div>
                </p-accordionTab>
                <p-accordionTab header="What if I can’t answer all the questions on the profile page?">
                    <div class="line-height-3">Listen, do your best. The info is being asked because it tells a producer a story about you. Are you green? Do you have a working career in the industry? These are things a producer can read quickly.  Come back and build your reel and resume anytime you want. Think of this as your hand shake getting you a step closer to the job you seek.</div>
                </p-accordionTab>
                <p-accordionTab header="Can I use my profile page as a webpage without worrying about the receiving person having to sign in or sign up with Crew Resumes?">
                    <div class="line-height-3">Yes, you sure can use it. And you will never have to work on a webpage for yourself again. We will do that for you as the industry needs to change and grow. No one will need to sign in or sign up to view your page.</div>
                </p-accordionTab>
                <p-accordionTab header="Am I being marketed by Crew Resumes?">
                    <div class="line-height-3">Yes, you in fact are being marketed. Pam Bouvier continues to cast and produce in the film industry and has in fact a plan in place to make sure you are found from all over the world quickly. Not to be selfish, but we need to be able to find cast and crew when we want to. And we want the networks and studios to know that Crew Resumes is the fastest way to replace, find or hire talent immediately. Yes, you will be marketed if you are a member of the Pam Bouvier Company ‘Crew Resumes’.</div>
                </p-accordionTab>
                <p-accordionTab header="What is your advice on my Bio?">
                    <div class="line-height-3">This is a great question. As a casting director the bio always seemed to reflect the successes exactly like the resume. If you want to beat the crowd and stand out, make your bio unique to who you are. Try not to use the same info as on your resumes. Here is mine: Pam is a rather attractive tall slender female, mature by way of life lived, not age. She puts animals before people, goes crazy for tropical weather, and is a minimalist who’ll give the shirt off her back to anyone and everyone. Pam roots for the underdog and always, and I mean always, gives more than 100%. She’s bright- spirited, has survived all life has thrown at her, while raising a son alone, who is now thriving. Her creative mind is as fresh as a daisy. Her passion exceeds most. Pam is the type of person who always smiles and always tries to come from a place of love. Pam has been single twenty-eight years and has worked independently casting all media platforms while working with actors on development. She’s experienced a lot of shit shows. She is a storyteller and boy does she have some stories to tell. Book out soon.<br>
                    See? Isn’t that better than me listing my commercial spots? My movies? Ugh. Blah blah blah.
                    </div>
                </p-accordionTab>
                <p-accordionTab header="Is it only $15.00 a month for my own profile webpage in addition to being marketed around the world?">
                    <div class="line-height-3">That is correct. For $15.00 a month you are seen in the database designed to connect you to the hiring producer faster. This is not a job board, there are no reference links or other data to distract the producer from getting to you. Just your webpage profile and the producer searching. There is no middleman. You will be marketed around the world, as well as production companies, networks and studios in Los Angeles and Canada. The best part is your profile is now your webpage. All your information is in one spot laid out by producers looking to hire a cast or crew member. No one will need to sign up or sign in to view your new webpage. Simply use www.crewresumes.com/yournamehere and you will have a page needed by the industry professionals who hire cast and crew for all media platforms that include Motion Picture, Short Film, Television, Commercial, Infomercial, Documentary, Reality, Animation, Audience Music & Variety, Live Action, Live to Tape, Music Video, Radio, Podcast, Sports Multi Camera, Web Series, Social Media, YouTube and Gaming. There are also over 400 job titles to choose from so you can be searched down to the pinpoint of your skills and experience.</div>
                </p-accordionTab>
                <p-accordionTab header="Can I choose more than one Media Platform or Department?">
                    <div class="line-height-3">Yes. Many independent contractors work in both film or television and maybe even commercials. For an additional $1.00 you can add your profile to another Media Platform or Department.</div>
                </p-accordionTab>
            </p-accordion>
        </div>
    </div> 
</div>
