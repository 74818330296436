import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment'
import { Router } from '@angular/router';
import { UsersService } from '../../users/users.service';

@Component({
  selector: 'app-non-login-header',
  templateUrl: './non-login-header.component.html',
  styleUrls: ['./non-login-header.component.scss']
})
export class NonLoginHeaderComponent implements OnInit {
  keyWord: any =''
  searchKeyValue: any = ''
  cross: boolean = false
  
  /**
  * @constructor
  * @param {Router} router - For getting router details.
  */
  constructor(
    private router: Router,
    private userService: UsersService,
  ) { }

  ngOnInit(): void {
  }
  //for navigate the path 
  signUp(){
    window.location.href =
    environment.memberUrl + 'signup';
  }
  
  //for navigate the path 
  signIn(){
    window.location.href =
    environment.memberUrl
  }
  //for navigate the path 
  goList(){
    // window.location.href =
    // environment.memberUrl + 'signup';
    this.router.navigate(['/crew-list'])
  }
  //search
  onSearch(ev: any) {
    this.searchKeyValue = ev.target.value
    if(this.searchKeyValue != ''){
      this.cross = true
      // if(this.userService.getRouter() != '/crew-list'){
      //   this.router.navigate(['crew-list', this.searchKeyValue]);
      // }else{
      //   this.userService.emitData(this.searchKeyValue)
      //   this.router.navigate(['crew-list'])
      // }
      //this.router.navigate(['crew-list', this.searchKeyValue]);
      this.router.navigate(['crew-list'], {
        queryParams: { searchStr: this.searchKeyValue }
      });
    }
   }
   //clear the input field
   inputClear(){
    this.searchKeyValue = ''
    this.keyWord = ''
    this.cross = false
    // this.userService.emitData('')
    this.router.navigate(['crew-list']);
  }
}
