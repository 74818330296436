<div class="surface-ground px-4 py-6 md:px-6 text-700">
    <div class="flex flex-wrap mb-5 container">
        <div class="w-full lg:w-6 pr-0 lg:pr-5">
            <div class="font-normal text-2xl mt-3 mb-3 text-900">About Crew Resumes</div>
            
            <p class="line-height-3 mt-0 mb-5">
                Crew Resumes is the largest world-wide Cast and Crew database developed, 
                with over four hundred job titles designed for the hiring producer to find 
                talent quickly. Time is money and people in production move fast. 
                There are no ads, no job boards, and no fancy talk.  
                Your profile is developed and designed to get attention and 
                fit the needs of the producer or studio who needs to hire and 
                replace a crew member fast, or prep for a job in another country. 
                There is no middleman, just the producer searching and your profile page 
                that will be searchable under your country, state, city, media platform, 
                department, and job title.
            </p>
            <p class="line-height-3  mt-0 mb-5">
                If you work in both motion pictures and commercials, 
                then you can be found in both.  Or maybe you dance, 
                and music videos is your platform, but you also are a personal 
                assistant in film if given the chance? Do you work in different departments? 
                Do you write and produce? Or act and direct? Are you a seamstress in Idaho 
                who always wanted to work in the movie business but didn't know where to 
                post your resume? Can you travel with a 48-hour notice? If so, Crew Resumes is 
                for you.
            </p>
            <!-- link in page -->
            <p class="line-height-3  mt-0 mb-0">
                Your profile page may be used as your webpage such as <a href="/" style="text-decoration: none;">www.crewresumes.com/yournamehere</a>  and
                 allows each member to add every skill possible, 
                 loaded with information showing your multiple contacts, 
                 video links, IMDB links, social media links, webpages, agents, 
                 managers, attorney's, publicist's, genders, bios, mulitple resumes, 
                 all media platforms, and every department known to mankind. 
                 Choose from over four hundred job titles in the media and entertainment 
                 industry on every single media platform. Leave no question to the wonder, 
                 let the producers know what they need to know so they can make a decision fast.
            </p>
        </div>
        <!-- image in abaout page -->
        <div class="w-full lg:w-6 pl-0 lg:pl-5 pt-5">
            <img src="assets/images/aboutcrewresume.jpg" alt="About Crew Resumes" class="w-full border-round" style="height: 100%; object-fit: cover;">
        </div>
    </div>
    <div class="container">
        <div class="font-normal text-2xl mt-3 mb-3 text-900">About Pam</div>
        <p class="line-height-3  mt-0 mb-5">
            Pam Bouvier has been an industry professional in the media 
            and entertainment industry for over 25 years.  
            Her company in Los Angeles, California has cast hundreds  of commercials,  
            infomercials, television network promos, theatre and a decade of independent movies.
        </p>
        <p class="line-height-3  mt-0 mb-5">
            Pam has cast local, regional, and national commercial campaigns for Lowes, 
            Ford, VW, Acura, Mercedes Benz, Chevrolet, Oldsmobile, McDonalds, Ford, Oldsmobile,
            VW, Tide, Sears, Walmart, Coca Cola, Petco, Sunkist, Nascar, Budweiser, eHarmony, 
            Nike, Adidas, Colgate-Palmolive, Bank of America, QuickBooks, Miller Beer, FedEx, 
            Goodyear Tires, Levi's 501, and hundreds more, including studio promos like 
            Kangaroo Jack, Black X-Mas, Primeval, Movie Crashers, Wild Hogs, Enchanted, 
            Spiderman and Pinocchio for networks like Warner Bros, Sony, Miramax, and Disney.  
            Other networks include ABC, CBS, Disney, Discovery, Outdoor Channel, Weather Channel, 
            Fuse TV, Lifetime, Animal Planet, WWE, and Europe Nickelodeon.
        </p>
        <p class="line-height-3  mt-0 mb-5">
            Pam's impressive resume includes Nu-Image/Millennium Films, 
            BAC films/Les Films, de I’autre (France), Phase 4 Films, Circus Road Films, 
            Big Deal Pictures, Mighty Girl Productions, Second Unit Services, Day Dreaming 
            Pictures, Animals are People Too, Wanko Toys, Big Foot Entertainment, 
            Orange Bird Productions, 2nd Nature Films, TCS Productions, Film Plus (Germany).
        </p>
        <!-- <div class="font-normal text-2xl mt-3 mb-3 text-900">How Crew Resumes Started</div>
        <p class="line-height-3  mt-0 mb-5">
           During the pandemic of 2020, Pam Bouvier reached out to over one hundred film commission offices to discuss a portal where cast and crew can be found internationally in every job title possible. She discussed a website for producers and directors to get an idea of who is available in any region of the world. For independent contractors, it is not uncommon to want to help other crew members find work. Pam Bouvier created Crew Resumes to do just that. 
        </p> -->
    </div>
</div>
