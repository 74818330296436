import { Injectable,Inject  } from '@angular/core';
import { Title, Meta, MetaDefinition  } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    private metaTagService: Meta,
    private title: Title,
    private router: Router,
    @Inject(DOCUMENT) private dom : any
  ) {
   }

  updateTitle(title: string){
    this.title.setTitle(title);
  }

  updateCanonicalUrl(url:string){
    const head = this.dom.getElementsByTagName('head')[0];
    var element: HTMLLinkElement= this.dom.querySelector(`link[rel='canonical']`) || null
    if (element==null) {
      element= this.dom.createElement('link') as HTMLLinkElement;
      head.appendChild(element);
    }
    element.setAttribute('rel','canonical')
    element.setAttribute('href',url)
  }

  addTags(){
    this.metaTagService.addTags([
      { name: 'robots', content: 'index, follow' },
      { name: 'description', content: 'The largest media and entertainment cast and crew database in the world, developed by industry professionals who have experienced a need to prep, find, replace, or hire talent.' },
      { name: 'keywords', content: 'entertainment cast database, entertainment crew database' },
      { property: 'og:url', content: environment.baseUrl },
      { property: 'og:site_name', content: 'CrewResumes' },
      { property: 'og:locale', content: 'en_US' },
      { property: 'og:type', content: 'website' },
      { property: 'og:title', content: 'CrewResumes - Media And Entertainment Cast And Crew Database' },
      { property: 'og:description', content: 'The largest media and entertainment cast and crew database in the world, developed by industry professionals who have experienced a need to prep, find, replace, or hire talent.' },
      { property: 'og:image', content: environment.logoUrl },
    ]);
  }

  updateTags(metaTags: MetaDefinition[]){
    metaTags.forEach(m=> this.metaTagService.updateTag(m));
  }
  
  updateTag(obj :any){
    this.metaTagService.updateTag(obj)
  }
}

