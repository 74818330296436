<div class="progress-spinner" *ngIf="pageLoader">
    <p-progressSpinner></p-progressSpinner>
</div>
<div class="surface-ground px-4 pb-6 md:px-6" style="min-height: 450px;">
    <div class="text-2xl mb-3 pt-4">Links and Resources</div>
    <!-- <div class="links.length > 0 || resourceList.length > 0">
        Currently there is no links and resources.
    </div> -->
    <div>
        <p-tabView >
            <p-tabPanel header="Links">
                <div class="w-full" *ngIf="links.length > 0">
                    <ul class="list-none p-0 m-0 list-container">
                        <li *ngFor="let link of links">
                            <div class=" text-900 mb-2 text-xl">{{link.title}}</div>
                            <div class="line-height-3 text-600 mb-2" >{{link.description}}</div>
                            <div class="line-height-3 text-600" > <a class="style-link" href="{{link.link}}" target="_blank">{{link.link}}</a> </div>
                        </li>
                    </ul>
                </div>
                <div class="w-full" *ngIf="links.length == 0">
                    Currently there are no links.
                </div>
            </p-tabPanel>
            <p-tabPanel header="Resources">
                <div class="w-full" *ngIf="resourceList.length > 0">
                    <ul class="list-none p-0 m-0 list-container">
                        <li *ngFor="let resource of resourceList">
                            <div class=" text-900 mb-2 text-xl">{{resource.title}}</div>
                            <div class="line-height-3 text-600 mb-2">{{resource.description}}</div>
                            <div class="line-height-3 text-600" >
                                <a *ngIf="resource.link" class="style-link md:mr-2" href="{{resource.link}}" target="_blank">{{resource.link}}</a>
                                <a *ngIf="resource.source" class="style-link mt-2" href="{{resource.source}}" download="download">Download Resource</a>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="w-full" *ngIf="resourceList.length == 0">
                    Currently there are no resources.
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
</div>

