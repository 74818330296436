import { Component, OnInit, Inject, PLATFORM_ID} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { SeoService } from 'src/app/seo.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-legal-information',
  templateUrl: './legal-information.component.html',
  styleUrls: ['./legal-information.component.scss']
})
export class LegalInformationComponent implements OnInit {

  /**
    * @constructor
    * @param {Router} router - For getting router details.
    * @param {SeoService} seo - The userService service for call API.
  */
  constructor(
    private seo: SeoService,
    private router:Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit(): void {
    this.updateTags()
  }
  
  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
    }
  }
  //update tag
   updateTags(){
    this.seo.updateCanonicalUrl(environment.baseUrl + this.router.url)
    this.seo.updateTitle('CrewResumes - Legal Information')
    this.seo.updateTags([
      { name: 'description', content: 'The largest media and entertainment cast and crew database in the world, developed by industry professionals who have experienced a need to prep, find, replace, or hire talent.' },
      { property: 'og:url', content: environment.baseUrl + this.router.url},
      { property: 'og:title', content: 'CrewResumes - Legal Information' },
      { property: 'og:description', content: 'The largest media and entertainment cast and crew database in the world, developed by industry professionals who have experienced a need to prep, find, replace, or hire talent.' },
      { property: 'og:image', content: environment.logoUrl },
    ]);
   }
   
}
