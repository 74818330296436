import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Router } from '@angular/router';
import { SeoService } from 'src/app/seo.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss'],
})
export class AboutUsComponent implements OnInit {
  /**
   * @constructor
   * @param {Router} router - For getting router details.
   * @param {SeoService} seo - The seo service for call API.
   */
  constructor(
    private seo: SeoService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {}

  ngOnInit(): void {
    this.updateTags();
  }
  //update the tag
  updateTags() {
    this.seo.updateCanonicalUrl(environment.baseUrl + this.router.url);
    this.seo.updateTitle(
      "About Us | World's Largest Crew Resume Database"
    );
    this.seo.updateTags([
      {
        name: 'description',
        content:
          'Learn about our industry professional-developed platform, addressing the need to prep, find, replace, or hire talent effortlessly.',
      },
      {
        property: 'og:url',
        content: environment.baseUrl + this.router.url,
      },
      {
        property: 'og:title',
        content: "About Us | World's Largest Crew Resume Database",
      },
      {
        property: 'og:description',
        content:
          'Learn about our industry professional-developed platform, addressing the need to prep, find, replace, or hire talent effortlessly.',
      },
      { property: 'og:image', content: environment.logoUrl },
    ]);
  }
  // updateTags() {
  //   this.seo.updateCanonicalUrl(environment.baseUrl + this.router.url);
  //   this.seo.updateTitle(
  //     'Crew Resumes - Media And Entertainment Cast And Crew Database'
  //   );
  //   this.seo.updateTags([
  //     {
  //       name: 'description',
  //       content:
  //         'The largest media and entertainment cast and crew database in the world, developed by industry professionals who have experienced a need to prep, find, replace, or hire talent.',
  //     },
  //     {
  //       property: 'og:url',
  //       content: environment.baseUrl + this.router.url,
  //     },
  //     {
  //       property: 'og:title',
  //       content: 'CrewResumes - Media And Entertainment Cast And Crew Database',
  //     },
  //     {
  //       property: 'og:description',
  //       content:
  //         'The largest media and entertainment cast and crew database in the world, developed by industry professionals who have experienced a need to prep, find, replace, or hire talent.',
  //     },
  //     { property: 'og:image', content: environment.logoUrl },
  //   ]);
  // }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
    }
  }
}
