<div class="surface-ground px-4 py-6 md:px-6 text-700">
    <div class="flex flex-wrap">
        <div class="w-full">
            <div class="font-normal text-2xl mt-3 mb-3 text-900">Legal Information</div>
            
            <p class="line-height-3 mt-0 mb-5">
                This begins with my Grandma’s unwritten recipe for the best noodle soup.
                The soup served me when I was sick, when I was sad, and when we had great family celebrations. When I moved away,
                I would hanker for the soup at various times, but nothing in the mainstream could duplicate its taste,
                even though the recipe seemed simple enough. Everything was lacking that homemade savor and authentic, 
                traditional spices.
            </p>
            <p class="line-height-3  mt-0 mb-5">
                This begins with my Grandma’s unwritten recipe for the best noodle soup.
                The soup served me when I was sick, when I was sad, and when we had great family celebrations. When I moved away,
                I would hanker for the soup at various times, but nothing in the mainstream could duplicate its taste,
                even though the recipe seemed simple enough. Everything was lacking that homemade savor and authentic, 
                traditional spices.
            </p>
           
            <div class="font-normal text-2xl mt-3 mb-3 text-900">Heading</div>
            <p class="line-height-3  mt-0 mb-5">
                This begins with my Grandma’s unwritten recipe for the best noodle soup.
                The soup served me when I was sick, when I was sad, and when we had great family celebrations. When I moved away,
                I would hanker for the soup at various times, but nothing in the mainstream could duplicate its taste,
                even though the recipe seemed simple enough. Everything was lacking that homemade savor and authentic, 
                traditional spices.
            </p>
        </div>
    </div>
</div>
