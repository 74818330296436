<div class="surface-ground px-4 pt-7 pb-8 md:px-6 overflow-hidden">
    <div class="font-bold text-900 text-3xl mb-3 text-center">How It Works</div>
    <div class="text-700 text-center mb-5 line-height-3 lg:w-7 mx-auto">Whether you are a producer searching for a cast or crew member for your next project or you are a cast or crew member who wants to join Crew Resumes, there are three steps.</div>
    <div class="flex lg:justify-content-center mb-5">
        <div class="py-3 pr-8 pl-3 w-30rem hidden lg:block">
            <img src="assets/images/feature-timeline-1.jpg" style="border-radius: 15px;" alt="Media Platform" class="w-full mr-8">
        </div>
        <div class="flex flex-column align-items-center w-2rem">
            <span class="bg-blue-500 text-0 flex align-items-center justify-content-center border-circle" style="min-width:2.5rem; min-height: 2.5rem">1</span>
            <div class="h-full bg-blue-500" style="width: 2px; min-height: 4rem"></div>
        </div>
        <div class="py-3 pl-5 lg:pl-8 pl-3 lg:w-30rem">
            <div class="text-900 text-xl mb-2 ">Media Platform</div>
            <div class="pt-3 border-top-1 border-300">
                <div class="mb-2 line-height-3"><span class="text-700 ">Choose your Media Platform- Motion Picture, Short Film, Television, Commercial, Infomercial, Documentary, Reality, Animation, Audience Music & Variety, Live Action, Live to Tape, Music Video, Radio, Podcast, Sports Multi Camera, Web Series, Social Media, YouTube or Gaming.</span></div>
            </div>
            <img src="assets/images/feature-timeline-1.jpg" style="border-radius: 15px;" alt="Media Platform" class="w-full mt-3 block lg:hidden">
        </div>
    </div>
    
    <div class="flex justify-content-center mb-5">
        <div class="py-3 pl-5 pr-3 lg:pr-8 lg:pl-3 lg:w-30rem flex-order-1 lg:flex-order-0">
            <div class="text-900 text-xl mb-2 ">Department</div>
            <div class="pt-3 border-top-1 border-300">
                <div class="mb-2 line-height-3"><span class="text-700 ">Choose your Department-Actors, Agents & Representatives, Art Department, Camera Department, Casting Directors, Digital, Directors, Editors, Gaming, Grip & Electric, Hair & Makeup, Military World, Models, Podcast, Producers, Production Office Support, Radio, Social Media, Sound Department & Music, Special Effects & Visual Effects, Talent Managers, TikTok Talent, Wardrobe & Costume, Writers, YouTube.</span></div>
            </div>
            <img src="assets/images/feature-timeline-2.jpg" style="border-radius: 15px;" alt="Department" class="w-full mt-3 block lg:hidden">
        </div>
        <div class="flex flex-column align-items-center w-2rem flex-order-0 lg:flex-order-1">
            <span class="bg-yellow-500 text-0 flex align-items-center justify-content-center border-circle" style="min-width:2.5rem; min-height: 2.5rem">2</span>
            <div class="h-full bg-yellow-500" style="width: 2px; min-height: 4rem"></div>
        </div>
        <div class="py-3 pl-8 pr-3 w-30rem hidden lg:block flex-order-2">
            <img src="assets/images/feature-timeline-2.jpg" style="border-radius: 15px;" alt="Department" class="w-full mr-8">
        </div>
    </div>
    <div class="flex justify-content-center">
        <div class="py-3 pr-8 pl-3 w-30rem hidden lg:block">
            <img src="assets/images/feature-timeline-3.jpg" style="border-radius: 15px;" alt="Job Title" class="w-full mr-8">
        </div>
        <div class="flex flex-column align-items-center w-2rem">
            <span class="bg-cyan-500 text-0 flex align-items-center justify-content-center border-circle" style="min-width:2.5rem; min-height: 2.5rem">3</span>
            <div class="h-full bg-cyan-500" style="width: 2px; min-height: 4rem"></div>
        </div>
        <div class="py-3 pl-5 lg:pl-8 pl-3 lg:w-30rem">
            <div class="text-900 text-xl mb-2 ">Job Title</div>
            <div class="pt-3 border-top-1 border-300">
                <div class="mb-2 line-height-3"><span class="text-700 ">Choose your Job Title. Our machine will guide you through the steps to build your profile on our world-wide database and create a webpage you can share with potential agents or producers.</span></div>
            </div>
            <img src="assets/images/feature-timeline-3.jpg" style="border-radius: 15px;" alt="Job Title" class="w-full mt-3 block lg:hidden">
        </div>
    </div>
</div>
