<!-- page loader -->
<div class="progress-spinner gallery-spinner" *ngIf="pageLoader">
    <p-progressSpinner></p-progressSpinner>
</div>
<!-- back clicked  -->
<div class="surface-ground px-4 py-3 md:px-6">
    <div class=" cursor-pointer" (click)="backClicked()" 
        style="align-items: center; display:flex;"><i class="pi pi-arrow-left mr-3"></i>
        <h3 *ngIf="mediaType == 1">Photos</h3>
        <h3 *ngIf="mediaType == 2">Videos</h3>
        <h3 *ngIf="mediaType == 3">Voice Over</h3>
    </div>
</div>
<!-- for photos -->
<div class="surface-ground px-4 pb-7 md:px-6"  *ngIf="mediaType == 1">
    <div class="shadow-2 border-round surface-card p-3">
        <div>
            <div>
                <div>
                    <p-galleria [value]="mediaData" [(visible)]="displayCustom" 
                        [(activeIndex)]="activeIndex" [responsiveOptions]="responsiveOptions" 
                        [containerStyle]="{'max-width': '850px'}"
                        [circular]="true" [fullScreen]="true" [showItemNavigators]="true" 
                        [showThumbnails]="false" [baseZIndex]="100000"
                        >
                            <ng-template pTemplate="item" let-item>
                                <img src="{{item.source}}" class="gallery-image-fullscreen"/>
                            </ng-template>
                            <ng-template pTemplate="thumbnail" let-item>
                                <div class="grid grid-nogutter justify-content-center">
                                    <img src="{{item.source}}"  style="display: block;"/>
                                </div>
                            </ng-template>
                            <ng-template pTemplate="caption" let-item >
                                <h4 *ngIf="item.source" style="margin-bottom: .5rem; color: #ffffff;">{{item.title}}</h4>
                                <p *ngIf="item.source">{{item.description}}</p>
                            </ng-template>
                        </p-galleria>
                        <div class="grid">
                            <div *ngFor="let image of mediaData; let index = index;" class="lg:col-3 md:col-4 mb-3 col-12" key="index">
                                <img src="{{image.thumnail
                                }}" class="gallery-image" (click)="imageClick(index)"/>
                                <div class="text-medium mt-2">{{image.title}}</div>
                                 <div class="text-700 mt-1">{{image.description}}</div>
                            </div>
                        </div>        
                    <!-- <p-image class="img_gallery" src="{{item.source}}" [preview]="true"></p-image> -->
                    <!-- <img class="border-round-sm img_profile media-image-edit" src="{{item.source}}" style="object-fit: cover;" alt="Image">  -->
                    
                </div>
            </div>
        </div>
    </div>
</div>
<!-- for videos -->
<div class="surface-ground px-4 pb-7 md:px-6"  *ngIf="mediaType == 2">
    <div  class="shadow-2 border-round surface-card p-3">
        <div class="grid">
            <div class="lg:col-3 md:col-4 mb-3 col-12" *ngFor="let item of mediaData">
                 <div class=" ">
                    <video *ngIf="item.source" class="video_profile normal-video" height="180" controls>
                        <source class="" src="{{item.source}}" alt="Image" type="video/mp4">
                    </video>
                    <div *ngIf="!item.source">
                        <div class="linkDiv surface-border border-1" *ngIf="item.linkType == 3">
                            <a style="word-break: break-all;height: 100%;" class="block" target="_blank" href="{{item.mediaName}}">{{item.mediaName}}</a>
                        </div>
                        <youtube-player class="responsive-video-small iframe" *ngIf="item.linkType == 1 && item.youTubeLinkType != 1"
                        videoId="{{this.getId(item.mediaName)}}" 
                        suggestedQuality="highres" 
                        >
                      </youtube-player>
                      <iframe [src]="parseVimeo(item.mediaName)" *ngIf="item.linkType == 2" class="responsive-video-small iframe" class="iframe-player" allow="autoplay; fullscreen" allowfullscreen></iframe>

                      <iframe class="responsive-video-small iframe" *ngIf="item.linkType == 1 && item.youTubeLinkType == 1" class="iframe-player" [src]="safePlayListUrl(item.mediaName)" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

                    </div>
                   
                    <div class="text-medium mt-2">{{item.title}}</div>
                    <div class="text-700 mt-1">{{item.description}}</div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- for voice -->
<div  class="surface-ground px-4 pb-7 md:px-6" *ngIf="mediaType == 3">
    <div class="shadow-2 border-round surface-card p-3">
        <div class="grid">
            <div  class="pt-2 lg:col-4 md:col-6 mb-3 col-12" *ngFor="let item of mediaData">
                <div class="">
                    <audio controls style="width: 100%;" *ngIf="item.mediaType == 3" >
                        <source type="audio/mpeg"  src="{{item.source}}">
                    </audio>
                    <div class="linkDivAudio" *ngIf="item.mediaType == 5">
                        <a style="word-break: break-all; height: 100%;" class="block" target="_blank" href="{{item.mediaName}}">{{item.mediaName}}</a>
                    </div>
                    <div class="text-medium mt-2">{{item.title}}</div>
                    <div class="text-700 mt-2">{{item.description}}</div>
                </div>
            </div>
        </div>
    </div>
</div>