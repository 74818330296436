import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {MessageService} from 'primeng/api';
import { CommonService } from '../common.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  newsForm: FormGroup;
  submitted:boolean=false;


  /**
  * @constructor
  * @param {CommonService} common - The commonsservice service for call API.
  * @param {MessageService} messageService - Message is used to display alerts inline.
  */
  constructor(
    private messageService: MessageService,
    private common: CommonService
  ) { 
    //Form declaration
    this.newsForm = new FormGroup({
      email : new FormControl('',Validators.compose([ Validators.required,Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]))
    })
  }

  //for form controls
  get f() { return this.newsForm.controls; }

  ngOnInit(): void {
  }

  //send msg 
  send(){
    this.submitted = true;
    if(this.newsForm.invalid){
     this.messageService.add({severity:'error',
     summary: 'Error', 
     detail: 'Please fill up all the required fields'});
     return;
    }
    this.common.sendNewsLetter(this.newsForm.value).subscribe((x:any)=>{
      this.submitted = false;
      this.newsForm.reset()
      this.messageService.add({severity:'success',
      summary: 'Success',
      detail: 'Submit successfully'});
    })

  }
}
