import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthGuard } from '../guards/auth.guard';
import { Router } from '@angular/router';
@Injectable()
export class CrewResumesInterceptor implements HttpInterceptor {

  constructor(private route:Router,private auth:AuthGuard) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const userData = JSON.parse(this.auth.getUser()) 
    if(userData && userData != null){
      const token = userData
      const newrequest = request.clone({setHeaders:{
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      }})
      request = newrequest
     
    }else{
      const newrequest = request.clone({setHeaders:{
        'Content-Type': 'application/json',
      }})
      request = newrequest
    }
    return next.handle(request);
  }
}
