<!-- <div class="progress-spinner" *ngIf="crewList.length">
    <p-progressSpinner></p-progressSpinner>
</div> -->
<div class="progress-spinner" *ngIf="pageLoader">
    <p-progressSpinner></p-progressSpinner>
</div>
<div class="surface-ground px-4 pt-5 pb-8 md:px-6">
    
    <div class="grid">
        <div class="col-12 lg:hidden p-fluid">
            <!-- for search  -->
            <span class="relative">
                <!-- <i class="pi pi-search text-white-400"></i> -->
                <input type="text" autocomplete="off" (keyup.enter)="onSearch($event)" pInputText placeholder="Search"
                    class="bg-white-800 text-white-400 border-white-800" [(ngModel)]="keyWord"
                    style="padding-right: 94px; font-size: 100%;" />
                <i class="pi pi-times search-icons" *ngIf="cross" (click)="inputClear()"></i>
                <i class="pi pi-search search-icons" (click)="searchKey()"></i>
            </span>

        </div>
        <div class="text-900  text-xl md:text-3xl col-12" *ngIf="searchKeyValue">Search results for
        "{{searchKeyValue}}".</div>
        <div class="col-12">
            <div class="grid" style="align-items: center;">
                <!-- <div class="col-7"><i class="md:text-xl" *ngIf="totalRecords > 1">Showing results {{pageIndex}} - {{pageCount}} of {{totalRecords}}</i>
                    <i class="md:text-xl" *ngIf="totalRecords == 1">Showing result {{pageCount}} of {{totalRecords}}</i></div> -->
                <!-- <div class="col-7">
                    <i class="md:text-xl" *ngIf="totalRecords > 1">Search Results</i>
                    <i class="md:text-xl" *ngIf="totalRecords == 1">Search Results </i>
                </div> -->
                <div class="col-5 ml-auto">
                    <div style="float: right;">
                        <button type="button" pButton icon="pi pi-sort-alt" (click)="menu.toggle($event)"
                            class="text-600 surface-section border-1 surface-border w-7rem p-2 transition-duration-150"
                            iconPos="right" label="Sort By"></button>
                        <p-menu #menu [popup]="true" [model]="items" appendTo="body"></p-menu>
                    </div>
                    <!-- <div class="form-group ms-auto my-2 my-lg-0 pt-1" style="float: right;">
                        <select class="form-select form-select-sm" >
                        <option value="">Select</option>
                        <option value="1">Name: A to Z</option>
                        <option value="2">Name: Z to A</option>
                        </select>
                    </div> -->
                </div>
            </div>

        </div>
    </div>
    <!-- for dropdown and select filter  -->
    <div class="grid">
        <div class="col-12 md:col-5 lg:col-3">
            <div class="showfilter" [class.show]="show">
                <div class="block md:hidden">
                    <button class="w-full p-button-outlined p-button-secondary filter-btn" (click)="show = !show"
                    label="Filters" pButton type="button" icon="pi pi-angle-down" iconPos="right"></button>
                </div>
                <div class="filter-accordion mt-3 md:mt-0">
                <p-accordion [multiple]="true">
                    <p-accordionTab styleClass="mb-3" [selected]="true" header="Media Platforms">
                        <div class="p-fluid">
                            <p-multiSelect #myMediaPlatform [options]="fieldsList['mediaPlatform']" 
                                defaultLabel="Select a Media Platform" (onChange)="mediaPlatformSelect($event)"
                                optionLabel="label" optionValue="value"></p-multiSelect>
                        </div>
                        <div class="text-primary-500  mt-3 cursor-pointer" (click)="clearMediaPlatform()">
                            Clear</div>
                    </p-accordionTab><br>
                    <p-accordionTab [selected]="true" header="Departments">
                        <div class="p-fluid">
                            <div class="field">
                                <p-dropdown [options]="departmentList" optionLabel="name" optionValue="id" 
                                (onChange)="departmentSelect($event)" placeholder="Select a Department"></p-dropdown>
                            </div>
                        </div>
                        <div class="text-primary-500  mt-3 cursor-pointer" (click)="clearDepartment()">Clear
                        </div>
                    </p-accordionTab><br>
                    <p-accordionTab [selected]="true" header="Job Titles">
                        <div class="p-fluid">
                            <div class="field">
                                <p-multiSelect #mySubCatagory [disabled]="isDisabledSubCat" [options]="subCategoryList" optionLabel="name" optionValue="id"
                                (onChange)="subCategorySelect($event)" defaultLabel="Select a Job Title" >
                            </p-multiSelect>
                            </div>
                        </div>
                        <div class="text-primary-500  mt-3 cursor-pointer" (click)="clearSubCategory()">Clear
                        </div>
                    </p-accordionTab><br>
                    <p-accordionTab [selected]="true" header="Location">
                        <div class="p-fluid">
                            <div class="field">
                                <label>Country</label>
                                <p-dropdown [options]="country" (onChange)="selectCountry($event)" 
                                    placeholder="Select a Country"></p-dropdown>
                            </div>
                            <div class="field">
                                <label>State</label>
                                <p-dropdown [options]="states" (onChange)="selectState($event)" 
                                [disabled]="isDisabledState" placeholder="Select a State"></p-dropdown>
                            </div>
                            <div class="field">
                                <label>City</label>
                                <p-dropdown [options]="cities" (onChange)="selectCity($event)"
                                [disabled]="isDisabledCity" placeholder="Select a City"></p-dropdown>
                            </div>
                            <div class="text-primary-500  mt-3 cursor-pointer" (click)="clearLocation()">
                                Clear</div>
                        </div>
                    </p-accordionTab><br>
                    
                    <!-- <p-accordionTab [selected]="true" header="Job Titles">
                        <div class="p-fluid">
                            <p-multiSelect [disabled]="isDisabledSubCat" [options]="subCategoryList" optionLabel="name" optionValue="id"
                                (onChange)="subCategorySelect($event)" defaultLabel="Select a Job Titles">
                            </p-multiSelect>
                        </div>
                        <div class="text-primary-500  mt-3 cursor-pointer" [ngClass]="{'disabledNoOfCasesDiv':isDisabledSubCat == true}" (click)="clearSubCategory()">Clear
                        </div>
                    </p-accordionTab> -->
                </p-accordion>
                <button type="button" pButton class="button-primary w-12" label="Clear All"
                    (click)="clearAll()"></button>
                </div>
            </div>
        </div>
        <div class="col-12 md:col-7 lg:col-9 lg:pl-4">
            <div class="" style="min-height:25rem;">
                <div class="">
                    <div class="grid border-1 surface-border border-round surface-section m-0">
                        <div class="col-12 md:col-6 lg:col-4 md:border-right-1 border-bottom-1 surface-border"
                            *ngFor="let crew of crewList">
                            <div class="p-3 cursor-pointer" (click)="goToProfile(crew.userName)">
                                <img *ngIf="crew.profileImageSrc" style="object-fit: cover;"
                                    src="{{crew.profileImageSrc}}" class="w-full border-round profile-image" />
                                <img *ngIf="!crew.profileImageSrc" style="object-fit: cover;"
                                    src="assets/images/profilePlaceholder.png" class="w-full border-round profile-image" />
                                <div class="flex align-items-center justify-content-between mt-4 mb-2">
                                    <span class="text-900  text-xl username">{{crew.name}}</span>
                                </div>
                                <div class="mb-1 text-700"><label>Media Platform: {{crew.mediaPlatform}} </label></div>
                                <div class="mb-1 text-700"><label>Department: {{crew.categoryName}} </label></div>
                                <div class="mb-1 text-700"><label>Job Title: {{crew.subcategoryName}} </label></div>
                                <div class="mb-1 text-700">
                                    <label class="useraddress"><i class="pi pi-map-marker mr-1"
                                            style="vertical-align: middle;"></i><span *ngIf="crew.address['city']">{{crew.address['city']}}, </span><span *ngIf="crew.address['state']">{{crew.address['state']}}, </span> <span *ngIf="crew.address['country']">{{crew.address['country']}}</span></label>
                                </div>
                                <div class="text-700">
                                    <label><span *ngIf="crew.height">H- {{crew.height}} |</span>
                                        {{crew.unionsAndGuilds}}</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-12" *ngIf="noDataFound == 1">
                           <div class="p-3">
                                <div class=" text-xl">No user found.<br>
                                Put your profile here! Be the first!</div><br><br>
                                <button pButton pRippel label="Sign Up Now" class="p-button-primary  p-button-outlined" (click)="signUp()"></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- server side pagination -->
<p-paginator [rows]="12" #p
(onPageChange)="paginate($event)" 
[totalRecords]="totalRecords" 
[first]="start"
[rowsPerPageOptions]='[12,24,36, { showAll: "All" }]'>
</p-paginator>
<!-- alert msg -->
<p-toast position="top-right"></p-toast>