import { Component, OnInit, Inject, PLATFORM_ID} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { MessageService } from 'primeng/api';
import { UsersService } from '../../users.service';
import {Location} from '@angular/common';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { SeoService } from 'src/app/seo.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-crew-media',
  templateUrl: './crew-media.component.html',
  styleUrls: ['./crew-media.component.scss']
})
export class CrewMediaComponent implements OnInit {
  mediaData:any = []
  pageLoader: boolean = false;
  userId : any
  mediaType : any
  displayCustom: boolean = false;
  activeIndex: number = 0;
  photos :any = []
  responsiveOptions:any[] = [
    {
        breakpoint: '1024px',
        numVisible: 5
    },
    {
        breakpoint: '768px',
        numVisible: 3
    },
    {
        breakpoint: '560px',
        numVisible: 1
    }
];

  /**
  * @constructor
  * @param {UsersService} userService - The userService service for call API.
  * @param {MessageService} messageService - For showing message.
  * @param {Router} router - For getting router details.
  * @param {ActivatedRoute} route - For getting router details.
  * @param {SeoService} seo - The userService service for call API.
  * @param {DomSanitizer} sanitizer - turning it into a value that's safe to insert into the DOM.
  * @param {Location} _location - For back API go to last page.
  */
  constructor(
    private userService : UsersService,
    private messageService: MessageService,
    private _location: Location,
    private route: ActivatedRoute,
    private sanitizer: DomSanitizer,
    private seo: SeoService,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object
    ) 
    { 
      //geting id by routing
      this.route.paramMap.subscribe((paramMap: ParamMap) => {
        this.userId = paramMap.get("id");
        this.mediaType = paramMap.get("mediaType");
        if(this.userId != null){
          this.getProfileData()
        }
      });
    }

  ngOnInit(): void {
    // this.getProfileData()
    this.updateTags()
  }
  //update tags
  updateTags(){
    this.seo.updateCanonicalUrl(environment.baseUrl + this.router.url)
    this.seo.updateTitle('Crew Resumes - Media And Entertainment Cast And Crew Database')
    this.seo.updateTags([
      { name: 'description', content: 'The largest media and entertainment cast and crew database in the world, developed by industry professionals who have experienced a need to prep, find, replace, or hire talent.' },
      { property: 'og:url', content: environment.baseUrl + this.router.url},
      { property: 'og:title', content: 'CrewResumes - Media And Entertainment Cast And Crew Database' },
      { property: 'og:description', content: 'The largest media and entertainment cast and crew database in the world, developed by industry professionals who have experienced a need to prep, find, replace, or hire talent.' },
      { property: 'og:image', content: environment.logoUrl },
    ]);
   }
   
  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
    }
  }

  // image show in media page while click 
  imageClick(index: number) {
    this.activeIndex = index;
    this.displayCustom = true;
    this.pageLoader = true
    setTimeout(() => {
      this.pageLoader = false;
      },2000)
    
  }

  //back page 
  backClicked() {
    this._location.back();
  }

  /**
   * Get crew media profil list
   * @method getProfileData
   * @returns { Object } Object
   */
  getProfileData(){
    this.pageLoader = true
    this.mediaData = []
    this.userService.getMediaDetailsId(this.userId).subscribe((data : any)=>{
    this.pageLoader = false
    if(data.length > 0){
      // this.mediaData = data.filter((item: any)=> {
      //   return item.mediaType == this.mediaType;
      // })
      data.map((item: any)=> {
        if(this.mediaType == 1){
          if(item.mediaType == 1){
            this.mediaData.push(item)
          }
        }else if(this.mediaType == 2){
          if(item.mediaType == 2 ||  item.mediaType == 4){
            let urlsplit = item.mediaName.split(/^.*(youtu.be\/|v\/|embed\/|watch\?|playlist\?|youtube.com\/user\/[^#]*#([^\/]*?\/)*)\??v?=?([^#\&\?]*).*/);
            if(urlsplit[1] == "playlist?"){
              item.youTubeLinkType = 1
              item.mediaName = "https://www.youtube.com/embed/videoseries?" + urlsplit[3]
            }
            this.mediaData.push(item)
          }
        }else if(this.mediaType == 3){
          if(item.mediaType == 3 || item.mediaType == 5){
            this.mediaData.push(item)
          }
        }
      })
    }
    //console.log(this.mediaData)
    },(error : any) =>{
      this.pageLoader = false
     this.messageService.add({ severity: 'error', 
     summary: 'Error', 
     detail: error.error.error.message });
    })
  }

  safePlayListUrl(url :any){
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  // pattern for youtube video
  getId(url : any) {
    var urlsplit= url.split(/^.*(youtu.be\/|v\/|embed\/|watch\?|youtube.com\/user\/[^#]*#([^\/]*?\/)*)\??v?=?([^#\&\?]*).*/);
    return urlsplit[3]
    }
    parseVimeo(url : any) {
      const re = /\/\/(?:www\.)?vimeo.com\/([0-9a-z\-_]+)/i;
      const matches: any = re.exec(url);
      return this.sanitizer.bypassSecurityTrustResourceUrl("https://player.vimeo.com/video/"+matches[1]);
    }
}
 