import { Component, OnInit, ViewChild } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { UsersService } from '../../users.service';
import { MessageService } from 'primeng/api';
import { ActivatedRoute, NavigationExtras, ParamMap, Router } from '@angular/router';
import { environment } from 'src/environments/environment'
import { Dropdown } from 'primeng/dropdown';
import { Paginator } from 'primeng/paginator';
import { SeoService } from 'src/app/seo.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-crew-list',
  templateUrl: './crew-list.component.html',
  styleUrls: ['./crew-list.component.scss']
})
export class CrewListComponent implements OnInit {
  routeSubscription!: Subscription;
  selectedStates: any
  selectedCountries: any
  selectedCities: any
  selectedDepartment: any
  selectedSubCategory: any = []
  selectedMediaPlatform: any = []
  country: any = []
  states: any = []
  cities: any = [];
  crewList: any = []
  departmentList: any = []
  subCategoryList: any = []
  noDataFound: number = 0;
  fieldsList: any = {}
  searchKeyValue: any = ''
  items: any = []
  sortBy: any = ''
  cross: boolean = false
  keyWord: any = ''
  pageLoader: boolean = false
  totalRecords: any
  start: any = 0
  pageIndex: any = 0
  pageCount: any = 0
  length: any = 12
  crewLength: any
  isDisabledSubCat: boolean = true
  isDisabledState: boolean = true
  isDisabledCity: boolean = true
  title = ''
  show: boolean = false;
  private parametersObservable: any;
  searchKeyWord: any

  @ViewChild("myMediaPlatform", { static: false }) myMediaPlatform!: Dropdown
  @ViewChild("mySubCatagory", { static: false }) mySubCatagory!: Dropdown
  @ViewChild('p', { static: false }) paginator!: Paginator;

  /**
  * @constructor
  * @param {UsersService} userService - The userService service for call API.
  * @param {MessageService} messageService -  Message is used to display alerts inline.
  * @param {Router} router - For getting router details.
  * @param {ActivatedRoute} activateRoute - For getting router details.
  * @param {SeoService} seo - The SeoService service for call API.
  */
  constructor(
    private activateRoute: ActivatedRoute,
    private userService: UsersService,
    private messageService: MessageService,
    private router: Router,
    private seo: SeoService,

  ) {
    

    this.items = [
      { label: 'All' },
      {
        label: 'Name: A to Z', command: () => {
          this.onSort(1)
        }
      },
      {
        label: 'Name: Z to A', command: () => {
          this.onSort(2)
        }
      }
    ];
    this.getAllData()
    this.activateRoute.queryParams.subscribe((params: any) => {
      console.log("SUBSCRIPE PARAMS")
      this.start = params.pageRef ?? 0;
      this.searchKeyValue = params.searchStr ?? '';
      this.getCrewList();
    });
    // if (this.userService.getRouter() != '/crew-list') {
    //   this.activateRoute.params.subscribe((params) => {
    //     if (params['searchStr'] != undefined) {
    //       this.searchKeyValue = params['searchStr'];
    //     }
    //     else {
    //       delete params['searchStr']
    //       this.searchKeyValue = ''
    //     }
    //   })
    //   this.getCrewList();
    // } else {
    //   this.getCrewList();
    //   this.userService.subscriber$.subscribe(data => {
    //     if (data) {
    //       this.searchKeyValue = data
    //     } else {
    //       this.searchKeyValue = ''
    //     }
    //     this.getCrewList();
    //   });
    // }

    // this.activateRoute.params.subscribe((params) => {
    //   if (params['searchStr'] != undefined) {
    //       this.searchKeyValue = params['searchStr'];
    //       this.selectedMediaPlatform = sessionStorage.getItem("mediaPlatform") ? JSON.parse(sessionStorage.getItem("mediaPlatform") || '{}') : []
    //       this.selectedDepartment =  sessionStorage.getItem("department") ? JSON.parse(sessionStorage.getItem("department") || '{}') : ''
    //       this.selectedSubCategory =  sessionStorage.getItem("subcategory") ? JSON.parse(sessionStorage.getItem("subcategory") || '{}') : []
    //       this.selectedCountries = sessionStorage.getItem("country")
    //       this.selectedStates = sessionStorage.getItem("state") 
    //       this.selectedCities = sessionStorage.getItem("city")
    //       this.getCrewList();
    //     }else{
    //       delete params['searchStr']
    //       this.searchKeyValue = ''
    //       this.getCrewList()
    //   }
    // });
  }

  // for navigates the path to go to profile page 
  goToProfile(userName: any) {
    let myUser: any = {}
    sessionStorage.getItem("currentUser") ? myUser = JSON.parse(sessionStorage.getItem("currentUser") || '{}') : ''
    if (Object.keys(myUser).length > 0 && userName == myUser.personalInfo['userName']) {
      window.location.href =
        environment.memberUrl + 'crew-profile';
    } else {
      //[routerLink]="['/profile',crew.userName]"
      // this.router.navigate(['profile',userName]);
      this.router.navigate([userName]);
    }
  }

  ngOnInit(): void {
    // this.activateRoute.queryParamMap.subscribe((params: ParamMap) => {
    //   const startParam = params.get('start');
    //   if (startParam !== null) {
    //     this.start = parseInt(startParam, 0);
    //     this.getCrewList();
    //   }
    // });
    this.updateTags()
    // this.retrievePaginationState()

  }

  updateTags() {
    this.seo.updateCanonicalUrl(environment.baseUrl + this.router.url)
    this.seo.updateTitle('Crew Resumes - Media And Entertainment Cast And Crew Database')
    this.seo.updateTags([
      { name: 'description', content: 'The largest media and entertainment cast and crew database in the world, developed by industry professionals who have experienced a need to prep, find, replace, or hire talent.' },
      { property: 'og:url', content: environment.baseUrl + this.router.url },
      { property: 'og:title', content: 'CrewResumes - Media And Entertainment Cast And Crew Database' },
      { property: 'og:description', content: 'The largest media and entertainment cast and crew database in the world, developed by industry professionals who have experienced a need to prep, find, replace, or hire talent.' },
      { property: 'og:image', content: environment.logoUrl },
    ]);
  }
  /**
   * Get all item in list of data table
   * @method getAllData
   * @returns { Object } Object
  */
  getAllData() {
    this.userService.getCountries().subscribe((x: any) => {
      this.country = x
      //this.countryModel = sessionStorage.getItem("country") ? this.country.findIndex((item: any) => item === sessionStorage.getItem("country")) : ''
    }, (error: any) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.error.message });
    })
    this.userService.getFiels().subscribe((data: any) => {
      this.fieldsList = data
      //this.mediaPlatformModel = sessionStorage.getItem("mediaPlatform") ? JSON.parse(sessionStorage.getItem("mediaPlatform") || '{}') : ''
    }, (error: any) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.error.message });
    })
    this.userService.getCategory().subscribe((x: any) => {
      this.departmentList = x
      //this.departmentModel = sessionStorage.getItem("department") ? JSON.parse(sessionStorage.getItem("department") || '{}') : ''
    }, (error: any) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.error.message });
    });
  }

  /**
    * Get country list
    * @method selectCountry
    * @returns { Object } Object
  */
  selectCountry(event: any) {
    this.sortBy = ''
    this.selectedStates = ''
    this.selectedCities = ''
    this.cities = []
    this.states = []
    this.pageCount = 0
    this.pageIndex = 0
    this.start = 0
    this.length = 12
    // this.paginator.changePage(0);
    this.selectedCountries = event.value
    //sessionStorage.setItem("country", this.selectedCountries)
    this.getCrewList()
    this.userService.getStates(this.selectedCountries).subscribe((x: any) => {
      this.isDisabledState = false
      this.states = x
      //this.stateModel = sessionStorage.getItem("state") ? this.states.findIndex((item: any) => item === sessionStorage.getItem("state")) : ''
    }, (error: any) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.error.message });
    })
  }

  /**
   * Get state list
   * @method selectState
   * @returns { Object } Object
   */
  selectState(event: any) {
    this.sortBy = ''
    this.selectedCities = ''
    this.cities = []
    this.start = 0
    this.length = 12
    this.pageCount = 0
    this.pageIndex = 0
    this.selectedStates = event.value
    //sessionStorage.setItem("state", this.selectedStates)
    this.getCrewList()
    this.userService.getCities(this.selectedStates, this.selectedCountries).subscribe((x: any) => {
      this.isDisabledCity = false
      this.cities = x
      //this.cityModel = sessionStorage.getItem("city") ? this.cities.findIndex((item: any) => item === sessionStorage.getItem("city")) : ''
    }, (error: any) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.error.message });
    })
  }

  //for select city & filter
  selectCity(event: any) {
    this.start = 0
    this.length = 12
    this.pageCount = 0
    this.pageIndex = 0
    this.selectedCities = event.value
    //sessionStorage.setItem("city", this.selectedCities)
    this.getCrewList()
  }

  /**
   * Get department list
   * @method departmentSelect
   * @returns { Object } Object
   */
  departmentSelect(ev: any) {
    this.start = 0
    this.length = 12
    this.subCategoryList = []
    this.mySubCatagory.value = ''
    this.pageCount = 0
    this.pageIndex = 0
    // this.paginator.changePage(0);
    this.selectedDepartment = ev.value
    //sessionStorage.setItem("department", JSON.stringify(this.selectedDepartment))
    this.getCrewList()
    this.userService.getSubCategoryList(this.selectedDepartment).subscribe((data: any) => {
      this.isDisabledSubCat = false
      this.subCategoryList = data
      //this.subCatagoryModel = sessionStorage.getItem("subcategory") ? JSON.parse(sessionStorage.getItem("subcategory") || '{}') : ''
    }, (error: any) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.error.message });
    })
  }

  //for select subCategory & filter
  subCategorySelect(ev: any) {
    this.start = 0
    this.length = 12
    this.pageCount = 0
    this.pageIndex = 0
    this.selectedSubCategory = ev.value
    //sessionStorage.setItem("subcategory", JSON.stringify(this.selectedSubCategory))
    this.getCrewList()
  }

  //for select mediaPlatform & filter
  mediaPlatformSelect(ev: any) {
    this.start = 0
    this.length = 12
    this.pageCount = 0
    this.pageIndex = 0
    // this.paginator.changePage(0);
    this.selectedMediaPlatform = ev.value
    // sessionStorage.setItem("mediaPlatform", JSON.stringify(this.selectedMediaPlatform))
    this.getCrewList()
  }

  /**
   * Get crew list
   * @method getCrewList
   * @returns { Object } Object
   */
  getCrewList() {
    this.pageLoader = true
    this.crewList = []
    let filterStr = "";
    let filter: any = {};
    // if (this.start !== null && this.start !== '') {
    //   filterStr += filterStr != '' ? `&start=${this.start}&length=${this.length}` : `?start=${this.start}&length=${this.length}`;
    // }
    if (this.selectedCountries != null && this.selectedCountries != '') {
      filter['country'] = this.selectedCountries
    }
    if (this.selectedStates != null && this.selectedStates != '') {
      filter['state'] = this.selectedStates
    }
    if (this.selectedCities != null && this.selectedCities != '') {
      filter['city'] = this.selectedCities
    }
    if (this.selectedDepartment != null && this.selectedDepartment != '') {
      let selectedDepartment = []
      selectedDepartment.push(this.selectedDepartment)
      filter['categories'] = selectedDepartment
    }
    if (this.selectedSubCategory.length > 0) {
      filter['subcategories'] = this.selectedSubCategory
    }
    if (this.selectedMediaPlatform.length > 0) {
      filter['mediaPlatform'] = this.selectedMediaPlatform
    }
    if (Object.keys(filter).length > 0) {
      filterStr += `?filter=${JSON.stringify(filter)}`
    }
    if (this.searchKeyValue != '') {
      filterStr += filterStr != '' ? `&searchStr=${this.searchKeyValue}` : `?searchStr=${this.searchKeyValue}`
    }
    if (this.sortBy != '') {
      filterStr += filterStr != '' ? `&sortBy=${JSON.stringify(this.sortBy)}` : `?sortBy=${JSON.stringify(this.sortBy)}`
    }
    if (this.start !== null && this.start !== '') {
      filterStr += filterStr != '' ? `&start=${this.start}&length=${this.length}` : `?start=${this.start}&length=${this.length}`;
    }
    // filterStr += filterStr != '' ? `&start=${this.start}&length=${this.length}` : `?start=${this.start}&length=${this.length}`;
    //console.log("filterStr", filterStr)
    this.userService.getCrewList(filterStr).subscribe((x: any) => {
      this.pageLoader = false;
      this.totalRecords = x.totalRecords
      if (x.totalRecords > 0) {
        this.crewList = x.crews
        this.crewLength = x.crews.length
        let totalPage = 0;
        if (this.start == 0) {
          this.pageIndex = 1;
          if (x.totalRecords < this.length) {
            this.pageCount = x.totalRecords;
          } else {
            this.pageCount = this.length;
          }
          totalPage = 1;
        } else {
          totalPage = (this.start / 12) + 1;
          if (totalPage * 12 <= x.totalRecords) {
            this.pageCount = totalPage * 12;
          } else {
            this.pageCount = x.totalRecords;
          }
        }
      } else {
        this.pageCount = 0;
        this.pageIndex = 0;
      }
      this.noDataFound = 0;
      if (this.crewList.length == 0) { this.noDataFound = 1; }
    }, (error: any) => {
      this.pageLoader = false;
      this.messageService.add({ severity: 'error', summary: 'Error', detail: error.error.error.message });
      this.noDataFound = 1;
    })
  }

  //for server side pagination
  paginate(event: any) {
    if (event.page > 0) {
      this.pageIndex = event.first + 1
      this.start = event.first
      this.length = event.rows

    } else {
      this.start = 0
      this.pageIndex = 1;
      this.pageCount = event.rows
      this.length = event.rows

    }
    // this.storePaginationState()
    this.router.navigate([], {
      queryParams: { pageRef: this.start },
      queryParamsHandling: 'merge'
    });

    this.getCrewList()
  }

  // storePaginationState() {
  //   localStorage.setItem('paginationState', JSON.stringify(this.start));
  // }

  // retrievePaginationState() {
  //   const storedState = localStorage.getItem('paginationState');
  //   if (storedState && this.start) {
  //     const start = JSON.parse(storedState);
  //     this.start = start;
  //     this.getCrewList();
  //   } else {
  //     localStorage.removeItem('paginationState');
  //   }
  // }
  // for search event
  onSearch(ev: any) {
    this.searchKeyValue = ev.target.value
    if (this.searchKeyValue != '') {
      this.cross = true
      this.getCrewList()
    }
  }

  searchKey() {
    this.searchKeyValue = this.keyWord
    if (this.searchKeyValue != '') {
      this.cross = true
      this.getCrewList()
    }
  }

  //sorting alphabet wise
  onSort(method: any) {
    this.sortBy = method
    if (this.sortBy != '') {
      this.getCrewList()
    }
  }

  //to convert into feet 
  toFeet(n: number) {
    let realFeet = ((n * 0.393700) / 12);
    let feet = Math.floor(realFeet);
    let inches = Math.round((realFeet - feet) * 12);
    let amp = String.fromCodePoint(0x00002032);
    return feet + `${amp}` + ' ' + inches + `${amp}${amp}` + `/` + n + `cm`;
  }

  //clear input field
  inputClear() {
    this.searchKeyValue = ''
    this.keyWord = ''
    this.cross = false
    this.start = 0
    this.length = 12
    this.getCrewList()
  }

  //clear input field
  clearLocation() {
    this.country = []
    this.cities = []
    this.states = []
    this.selectedCountries = ''
    this.selectedStates = ''
    this.selectedCities = ''
    //sessionStorage.removeItem('country')
    //sessionStorage.removeItem('state')
    //sessionStorage.removeItem('city')
    this.start = 0
    this.length = 12
    this.pageCount = 0
    this.pageIndex = 0
    this.paginator.changePage(0);
    this.getAllData()
    this.getCrewList()
    this.isDisabledCity = true
    this.isDisabledState = true
  }

  //clear input field
  clearMediaPlatform() {
    this.fieldsList = []
    this.myMediaPlatform.value = ''
    this.selectedMediaPlatform = []
    //sessionStorage.removeItem('mediaPlatform')
    this.start = 0
    this.length = 12
    this.pageCount = 0
    this.pageIndex = 0
    this.paginator.changePage(0);
    this.getAllData()
    this.getCrewList()
  }

  //clear input field
  clearDepartment() {
    this.departmentList = []
    this.selectedDepartment = ''
    this.subCategoryList = []
    this.selectedSubCategory = []
    this.mySubCatagory.value = ''
    //sessionStorage.removeItem('department')
    //sessionStorage.removeItem('subcategory')
    this.start = 0
    this.length = 12
    this.pageCount = 0
    this.pageIndex = 0
    this.paginator.changePage(0);
    this.getAllData()
    this.getCrewList()
    this.isDisabledSubCat = true
  }

  //clear input field
  clearSubCategory() {
    this.subCategoryList = []
    this.mySubCatagory.value = ''
    this.selectedSubCategory = []
    //sessionStorage.removeItem('subcategory')
    this.start = 0
    this.length = 10
    this.pageCount = 0
    this.pageIndex = 0
    this.paginator.changePage(0);
    this.getAllData()
    this.getCrewList()
  }

  //clear input field
  clearAll() {
    this.country = []
    this.cities = []
    this.states = []
    this.selectedCountries = ''
    this.selectedStates = ''
    this.selectedCities = ''
    //sessionStorage.removeItem('country')
    //sessionStorage.removeItem('state')
    //sessionStorage.removeItem('city')
    this.fieldsList = []
    this.myMediaPlatform.value = ''
    this.selectedMediaPlatform = []
    //sessionStorage.removeItem('mediaPlatform')
    this.departmentList = []
    this.selectedDepartment = ''
    //sessionStorage.removeItem('department')
    this.subCategoryList = []
    this.mySubCatagory.value = ''
    this.selectedSubCategory = []
    //sessionStorage.removeItem('subcategory')
    this.userService.emitData('')
    this.sortBy = ''
    this.searchKeyValue = ''
    this.start = 0
    this.length = 12
    this.pageCount = 0
    this.pageIndex = 0
    this.paginator.changePage(0);
    this.getAllData()
    this.getCrewList()
    this.isDisabledSubCat = true
    this.isDisabledCity = true
    this.isDisabledState = true
  }

  //for navigate the path to signup page
  signUp() {
    window.location.href =
      environment.memberUrl + 'signup';
  }
}
