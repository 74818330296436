import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutUsComponent } from './about-us/about-us.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { CookieComponent } from './cookie/cookie.component';
import { DataPrivacyComponent } from './data-privacy/data-privacy.component';
import { DisclaimerComponent } from './disclaimer/disclaimer.component';
import { FaqComponent } from './faq/faq.component';
import { HomeComponent } from './home/home.component';
import { HowItWorksComponent } from './how-it-works/how-it-works.component';
import { LegalInformationComponent } from './legal-information/legal-information.component';
import { LinkResourcesComponent } from './link-resources/link-resources.component';
import { ReturnPolicyComponent } from './return-policy/return-policy.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data: { title: 'CrewResumes' },
  },
  {
    path: 'about-us',
    component: AboutUsComponent,
    data: { title: 'CrewResumes' },
  },
  {
    path: 'contact-us',
    component: ContactUsComponent,
    data: { title: 'CrewResumes' },
  },
  {
    path: 'data-privacy',
    component: DataPrivacyComponent,
    data: { title: 'CrewResumes' },
  },
  {
    path: 'faq',
    component:FaqComponent,
    data: { title: 'CrewResumes' },
  },
  {
    path: 'how-it-works',
    component: HowItWorksComponent,
    data: { title: 'CrewResumes' },
  },
  {
    path: 'legal-information',
    component: LegalInformationComponent,
    data: { title: 'CrewResumes' },
  },
  {
    path: 'term-of-service',
    component: TermsOfServiceComponent,
    data: { title: 'CrewResumes' },
  },
  {
    path: 'cookie',
    component: CookieComponent,
    data: { title: 'CrewResumes' },
  },
  {
    path: 'disclaimer',
    component: DisclaimerComponent,
    data: { title: 'CrewResumes' },
  },
  {
    path: 'return-policy',
    component: ReturnPolicyComponent,
    data: { title: 'CrewResumes' },
  },
  {
    path: 'link-resources',
    component: LinkResourcesComponent,
    data: { title: 'CrewResumes' },
  },
 
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CommonRoutingModule { }
