import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment'
import { Router } from '@angular/router';
import { UsersService } from '../../users/users.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  keyWord: any = ''
  searchKeyValue: any = ''
  cross: boolean = false
  profileImage: any
  showMenu = true
  feature: any = []

  /**
  * @constructor
  * @param {Router} router - For getting router details.
  * @param {UsersService} userService - The userService service for call API.
  */
  constructor(
    private router: Router,
    private userService: UsersService
  ) { }

  ngOnInit(): void {
    this.feature = [
      // {label: 'Edit Profile', page: 'Edit Profile' , number: 1 ,command: () => {
      //   this.router.navigate(['/crew-profile'])
      // }},
      {
        label: 'Logout', page: 'Logout', number: 2, command: () => {
          this.logout()
        }
      },
    ];

    this.profileImage = sessionStorage.getItem("profileImage")
  }
  //for logout 
  logout() {
    sessionStorage.clear()
    this.router.navigate(['/'])
  }
  
  onClick() {
    this.showMenu = false
  }
  //navigate to home
  home() {
    // window.location.href =
    // environment.mainUrl
    // this.router.navigate(['/crew-profile'])
    this.router.navigate(['/'])
    }

  //for navigate the path 
  gotoProfile() {
    let token = sessionStorage.getItem('b-sessionToken');
    if (token) {
      window.location.href =
        environment.memberUrl + 'dashboard';
    } else {
      window.location.href =
        environment.memberUrl
    }

  }
  // for navigate the path 
  goList(){
    // window.location.href =
    // environment.memberUrl + 'signup';
    this.router.navigate(['/crew-list'])
  }
 // for navigate the path 
  signUp() {
    window.location.href =
    environment.memberUrl + 'signup';
  }
  // for navigate the path 
  signIn() {
    window.location.href =
    environment.memberUrl
  }

  // search
  onSearch(ev: any) {
    this.searchKeyValue = ev.target.value
    if(this.searchKeyValue != ''){
      this.cross = true
      // if(this.userService.getRouter() != '/crew-list'){
      //   this.router.navigate(['crew-list', this.searchKeyValue]);
      // }else{
      //   this.userService.emitData(this.searchKeyValue)
      //   this.router.navigate(['crew-list'])
      // }
      //this.router.navigate(['crew-list', this.searchKeyValue]);
      this.router.navigate(['crew-list'], {
        queryParams: { searchStr: this.searchKeyValue }
      });
    }
   }

   //clear the input field
   inputClear(){
    this.searchKeyValue = ''
    this.keyWord = ''
    this.cross = false
    // this.userService.emitData('')
    this.router.navigate(['crew-list']);
  }

}
