import { Component, OnInit, Inject, PLATFORM_ID} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { MessageService } from 'primeng/api';
import { UsersService } from '../../users.service';
import {Location} from '@angular/common';
import * as moment from 'moment';
import { SeoService } from 'src/app/seo.service';
import { environment } from 'src/environments/environment';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  profileSnapData:any = {}
  display1:boolean = false;
  display2:boolean = false;
  display3:boolean = false;
  userName : any
  profileData:any = {}
  pageLoader: boolean = false
  showMore : boolean = false;
  status: boolean = false;
  professionalInfo: any
  skillInfo: any
  attribute: any
  personalInfo: any
  contactInfo: any
  displayCustom: boolean = false;
  activeIndex: number = 0;
  photos :any = []
  age: any
  bio: any
  userId: any
  image: any
  subscriptionStatus: any
  responsiveOptions:any[] = [
    {
        breakpoint: '1024px',
        numVisible: 5
    },
    {
        breakpoint: '768px',
        numVisible: 3
    },
    {
        breakpoint: '560px',
        numVisible: 1
    }
];
  /**
  * @constructor
  * @param {UsersService} userService - The userService service for call API.
  * @param {MessageService} messageService - For showing message.
  * @param {Router} router - For getting router details.
  * @param {ActivatedRoute} route - For getting router details.
  * @param {SeoService} seo - The userService service for call API.
  * @param {Location} _location - For back API go to last page.
  */
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private userService : UsersService,
    private messageService: MessageService,
    private _location: Location,
    private seo: SeoService,
    public title: Title,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    //for geting id by route.
    // this.route.paramMap.subscribe((paramMap: ParamMap) => {
    //   this.userName = paramMap.get("userName");
    //   this.userId = paramMap.get("id");
    //   if(this.userName != null || this.userId != null){
    //     // this.getProfileData()
    //   }
    // });
   }

  ngOnInit(): void {
    // console.log("Test")
    this.pageLoader = true
    this.profileSnapData = this.route.snapshot.data['detail'];
    let errorCode = this.route.snapshot.data['detail']?.error?.error?.statusCode
    if(errorCode >= 400){
      this.pageLoader = false
      this.subscriptionStatus = 0
      this.messageService.add({ severity: 'error', 
      summary: 'Error', 
      detail: this.profileSnapData.error.error.message });
    }else{
      this.pageLoader = false
      this.subscriptionStatus = 1
      this.getProfileData()
    }
    // const {name = ''} = this.route.snapshot.data['detail']?.personalInfo || {};
    //this.title.setTitle(name);
    // if(this.profileData) {
    //   this.updateTags();

    // }
   
    // console.log(this.profileData);
    // console.log("Test2")
   
  }
  
  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
    }
   }
   //update tags
   updateTags(){
    this.seo.updateCanonicalUrl(environment.baseUrl + this.router.url)
    this.seo.updateTitle(this.profileData.personalInfo?.name)
    let bio = 'The largest media and entertainment cast and crew database in the world, developed by industry professionals who have experienced a need to prep, find, replace, or hire talent 1.';
    // let bio = this.profileData.personalInfo?.bio != undefined ? ((this.profileData.personalInfo?.bio).slice(0,160)) +'...' : 'The largest media and entertainment cast and crew database in the world, developed by industry professionals who have experienced a need to prep, find, replace, or hire talent 1.'
    if (this.profileData.personalInfo.socialMediaDescription) {
      bio = this.profileData.personalInfo.socialMediaDescription;
    } 
    if (!this.profileData.personalInfo.socialMediaDescription && this.profileData.personalInfo.bio){
      bio = this.profileData.personalInfo.bio.slice(0,150)+'...'
    }
    let socialMediaImage =  this.profileData.personalInfo.socialMediaImageSrc ?  this.profileData.personalInfo?.socialMediaImageSrc : (this.profileData.personalInfo?.profileThumbnail ? this.profileData.personalInfo?.profileThumbnail : (this.profileData.personalInfo?.profileImageSrc ? this.profileData.personalInfo?.profileImageSrc : ''));
    this.seo.updateTags([
      { name: 'description', content: bio},
      { property: 'og:url', content: environment.baseUrl + this.router.url},
      { property: 'og:title', content: this.profileData.personalInfo?.name},
      { property: 'og:description', content: bio},
      // { property: 'og:image', content: this.profileData.personalInfo?.profileThumbnail ?? this.profileData.personalInfo?.profileImageSrc },
      { property: 'og:image', content: socialMediaImage}
    ]);
   }

   //for gallery image
  imageClick(index: number) {
    this.activeIndex = index;
    this.displayCustom = true;
    this.pageLoader = true
    setTimeout(() => {
      this.pageLoader = false;
      },2000)
  }

  //for dialog box
  showDialog() {
    this.display1 = true;
  }

  //for dialog box
  show() {
    this.display2 = true;
  }

  //for dialog box Connect button
  showConnect() {
    this.display3 = true;
  }

  //for back page
  backClicked() {
    this._location.back();
  }

  //calculate the age
  public calculateAge(birthdate?: any): number {
    let age = moment().diff(birthdate, 'years');
    return age;
  }

   /**
   * Get all item list in profile page 
   * @method getProfileData
   * @returns { Object } Object
   */
  getProfileData_bk(){
    this.pageLoader = true;
    let method : any
    method = this.userId != null ? this.userService.getProfileDetailsId(this.userId) : this.userService.getProfileDetailsUserName(this.userName)
    method.subscribe((data : any)=>{
    this.pageLoader = false
    this.subscriptionStatus = 1
    let tempData: any = {}
    tempData = data
    if(tempData.contactInfo.email != null || tempData.contactInfo.email != ''){
      delete tempData.contactInfo.email
      this.profileData = tempData
      this.age = this.calculateAge(this.profileData.dob)
    }
    this.personalInfo = Object.keys(this.profileData.personalInfo).length
    this.professionalInfo = Object.keys(this.profileData.professionalInfo).length
    this.skillInfo = Object.keys(this.profileData.skillAndTrainings).length
    this.attribute = Object.keys(this.profileData.attributes).length
    this.contactInfo = Object.keys(this.profileData.contactInfo).length
    let videos:any[] = [];
    let photos:any[] = [];
    let voices:any[] = [];
    if(this.profileData.medias.length > 0){
      this.profileData.medias.forEach((item:any) => {
        if(item.mediaType == '1' && photos.length < 6)
          photos.push(item)
        if(item.mediaType == '2' || item.mediaType == '4')
          videos.push(item)
        if(item.mediaType == '3' || item.mediaType == '5')
          voices.push(item)
      });
    }
    this.profileData.photos = photos;
    this.profileData.videos = videos;
    this.profileData.voices = voices;
    this.photos = photos
    this.updateTags()
    },(error : any) =>{
     this.pageLoader = false
     this.subscriptionStatus = 0
     this.messageService.add({ severity: 'error', 
     summary: 'Error', 
     detail: error.error.error.message });
    })
  }

  getProfileData(){
    this.subscriptionStatus = 1
    let tempData: any = {}
    tempData = this.profileSnapData
    if(tempData.contactInfo.email != null || tempData.contactInfo.email != ''){
      delete tempData.contactInfo.email
      this.profileData = tempData
      this.age = this.calculateAge(this.profileData.dob)
    }
    this.personalInfo = Object.keys(this.profileData.personalInfo).length
    this.professionalInfo = Object.keys(this.profileData.professionalInfo).length
    this.skillInfo = Object.keys(this.profileData.skillAndTrainings).length
    this.attribute = Object.keys(this.profileData.attributes).length
    this.contactInfo = Object.keys(this.profileData.contactInfo).length
    let videos:any[] = [];
    let photos:any[] = [];
    let voices:any[] = [];
    if(this.profileData.medias.length > 0){
      this.profileData.medias.forEach((item:any) => {
        if(item.mediaType == '1' && photos.length < 6)
          photos.push(item)
        if(item.mediaType == '2' || item.mediaType == '4')
          videos.push(item)
        if(item.mediaType == '3' || item.mediaType == '5')
          voices.push(item)
      });
    }
    this.profileData.photos = photos;
    this.profileData.videos = videos;
    this.profileData.voices = voices;
    this.photos = photos
    this.updateTags()
   
  }
  
  // imageOpen(event : any){
  //   this.status = true    
  // }
  // imageHide(event : any){
  //   this.status = false    
  // }

  getId(url : any) {
    var urlsplit= url.split(/^.*(youtu.be\/|v\/|embed\/|watch\?|youtube.com\/user\/[^#]*#([^\/]*?\/)*)\??v?=?([^#\&\?]*).*/);
    return urlsplit[3]
  }
  //convert to feet 
  toFeet(n: number) {
    let realFeet = ((n*0.393700) / 12);
    let feet = Math.floor(realFeet);
    let inches = Math.round((realFeet - feet) * 12);
    let amp = String.fromCodePoint(0x00002032);
    return feet + `${amp}` + ' ' + inches + `${amp}${amp}` + ` / ` + n + `cm`;
  }
  goLogin(){
    let token = sessionStorage.getItem('b-sessionToken');
    if (token) {
      window.location.href =
        environment.memberUrl + 'dashboard';
    } else {
      window.location.href =
        environment.memberUrl
  }
}
getWhatsAppLink(): string {
  const whatsappNumber = this.profileData.contactInfo.whatsAppNumber;
  const phoneNumber = whatsappNumber.replace(/\D/g, '');
  const whatsappLink = `https://wa.me/${phoneNumber}`;
  return whatsappLink;
}
}
