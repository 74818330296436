import { Component, OnInit } from '@angular/core';
import { SeoService } from './seo.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'crewResumes';
  isUserLoggedIn:any = false;

  constructor(
    private seo: SeoService
  ) {
      let token = sessionStorage.getItem('b-sessionToken');
      if(token) {
       this.isUserLoggedIn = true
      }
  }
  ngOnInit() {
    //this.seo.addTags()
  }
}
