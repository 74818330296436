<div class="surface-50 px-4 md:px-6 lg:px-6 border-1 surface-border border-x-none">
    <div class="grid flex-wrap -mr-3 -ml-3 md:text-left">
         <!-- for path navigation routerLink ussed -->
        <div class="col-12 md:col-6 lg:col-6 flex flex-column mt-2 py-4 px-3 border-bottom-1 lg:border-y-none md:border-right-1 surface-border">
            <span class="text-900 text-xl block">Company</span>
            <ul class="list-none p-0">
                <li><a tabindex="0" class="text-600 hover:text-900 transition-duration-150 cursor-pointer mt-3 block" style="text-decoration: none;"  routerLink="/">Home</a></li>
                <li><a tabindex="0" class="text-600 hover:text-900 transition-duration-150 cursor-pointer mt-3 block" style="text-decoration: none;"  routerLink="/about-us">About Us</a></li>
                <li><a tabindex="0" class="text-600 hover:text-900 transition-duration-150 cursor-pointer mt-3 block" style="text-decoration: none;"  routerLink="/contact-us">Contact Us</a></li>
                <li><a tabindex="0" class="text-600 hover:text-900 transition-duration-150 cursor-pointer mt-3 block" style="text-decoration: none;"  routerLink="/how-it-works">How It Works</a></li>
                <li><a tabindex="0" class="text-600 hover:text-900 transition-duration-150 cursor-pointer mt-3 block" style="text-decoration: none;"  routerLink="/faq">Frequently Asked Questions</a></li>
                <li><a tabindex="0" class="text-600 hover:text-900 transition-duration-150 cursor-pointer mt-3 block" style="text-decoration: none;"  routerLink="/link-resources">Links and Resources</a></li>
            </ul>
        </div>
       <!-- for path navigation routerLink ussed -->
        <!-- <div class="col-12 md:col-6 lg:col-4 flex flex-column mt-2 py-4 px-3 border-bottom-1 lg:border-y-none lg:border-right-1 surface-border"> -->
        <div class="col-12 md:col-6 lg:col-6 flex flex-column mt-2 py-4 px-3 lg:mb-0">
            <span class="text-900 text-xl block">Legal</span>
            <ul class="list-none p-0">

                <li><a tabindex="0" class="text-600 hover:text-900 transition-duration-150 cursor-pointer mt-3 block" style="text-decoration: none;"  routerLink="/data-privacy">Privacy Policy</a></li>
                <li><a tabindex="0" class="text-600 hover:text-900 transition-duration-150 cursor-pointer mt-3 block" style="text-decoration: none;"  routerLink="/cookie">Cookie Policy</a></li>
                <li><a tabindex="0" class="text-600 hover:text-900 transition-duration-150 cursor-pointer mt-3 block" style="text-decoration: none;"  routerLink="/term-of-service">Terms of Service</a></li>
                <!-- <li><a tabindex="0" class="text-600 hover:text-900 transition-duration-150 cursor-pointer mt-3 block" style="text-decoration: none;"  routerLink="/legal-information">Legal Information</a></li> -->
                <li><a tabindex="0" class="text-600 hover:text-900 transition-duration-150 cursor-pointer mt-3 block" style="text-decoration: none;"  routerLink="/return-policy">Return Policy</a></li>
                <li><a tabindex="0" class="text-600 hover:text-900 transition-duration-150 cursor-pointer mt-3 block" style="text-decoration: none;"  routerLink="/disclaimer">Disclaimer</a></li>
                <!-- <li><a tabindex="0" class="text-600 hover:text-900 transition-duration-150 cursor-pointer mt-3 block" style="text-decoration: none;"  href="https://app.termly.io/notify/a639e7b7-64f0-44b1-8d99-16c3deb2ca47" target="_blank">Do Not Sell My Info</a></li> -->
            </ul>
        </div>
        <!-- for sending msg from footer -->
        <!-- <div class="col-12 md:col-12 lg:col-4 flex flex-column mt-2 py-4 px-3 mb-4 lg:mb-0">
            <span class="text-900 text-xl block">Subscribe</span>
            <span class="text-500 block mt-4 line-height-3">Join our community to receive the latest updates and special promotions.</span>
            <form [formGroup]="newsForm">
            <div class="p-inputgroup mt-3 mx-auto lg:mx-0">
              
                <button type="button" pButton icon="pi pi-envelope" class="text-500 surface-100 surface-border"></button>    
                <input type="email" formControlName="email" autocomplete="off" [ngClass]="{ 'ng-dirty': submitted && f['email'].errors }" pInputText placeholder="Email" class="border-y-1 text-500 border-x-none surface-border">   
               
                <button type="button" pButton icon="pi pi-arrow-right" (click)="send()" class="text-500 surface-100 surface-border"></button>      
              
            </div>
            <div *ngIf="submitted && f['email'].errors" class="invalid-feedback">
                <small *ngIf="f['email'].errors['required']" class="p-error block">Email is required</small>
            </div>
        </form>
        </div> -->
    </div>
</div>    
<!-- for payments options   -->
<!-- <div class="surface-section px-3 py-4 lg:px-6 flex flex-column lg:flex-row justify-content-between align-items-center"> -->
<div class="surface-section px-3 py-4 lg:px-6 flex flex-column lg:flex-row  align-items-center">
    <!-- <div class="col-fixed flex flex-wrap flex-order-1 lg:flex-order-0 p-0"> -->
    <div class="col-fixed flex flex-wrap  lg:flex-order-0 p-0 lg:mr-3 mb-3 lg:mb-0 gap-3 justify-content-center">
        <img src="assets/images/card/discover.svg" class="w-3rem" alt="Discover">
        <img src="assets/images/card/storefront-1-25.svg" class="w-3rem" alt="Visa">
        <img src="assets/images/card/storefront-1-23.svg" class="w-3rem" alt="Mastercard">
        <img src="assets/images/card/storefront-1-21.svg" class="w-3rem" alt="Amex">
        <img src="assets/images/card/storefront-1-24.svg" class="w-3rem" alt="PayPal">
        <!-- <img src="assets/images/card/storefront-1-22.svg" class="w-3rem mr-3" alt="Apple Pay"> -->
    </div>
    <!-- for social media page linked with the website -->
    <!-- <div class="col-fixed flex align-items-center flex-order-0 lg:flex-order-1 p-0 mb-3 lg:mb-0 gap-3"> -->
    <div class="flex flex-wrap align-items-center ml-auto gap-3 justify-content-center">
        <!-- <a tabindex="0" target="_blank" class="cursor-pointer" href="https://web.whatsapp.com/">
            <img src="assets/images/social/whatsapp.png" width="24" height="24" class="block">
        </a> -->
        <a tabindex="0" target="_blank" class="cursor-pointer" href="https://www.facebook.com/crewresumes/">
            <!-- <i class="pi pi-facebook surface-900 p-1 text-sm border-circle text-0"></i> -->
            <img src="assets/images/social/facebook.png" width="24" height="24" class="block" >
        </a>
        <a tabindex="0" target="_blank" class="cursor-pointer" href="https://x.com/Crew_Resumes">
            <!-- <i class="pi pi-twitter surface-900 p-1 text-sm border-circle text-0"></i> -->
            <img src="assets/images/social/x.png" width="24" height="24" class="block">
        </a>
        <a tabindex="0" target="_blank" class="cursor-pointer" href="https://www.instagram.com/crewresumes/">
            <!-- <i class="pi pi-instagram surface-900 p-1 text-sm border-circle text-0"></i> -->
            <img src="assets/images/social/instagram.png" width="24" height="24" class="block">
        </a>
        <!-- <a tabindex="0" target="_blank" class="cursor-pointer" href="https://www.linkedin.com/in/pambouviercasting/">

            <img src="assets/images/social/linkedin.png" width="24" height="24" class="block">
        </a> -->
        <!-- external -->
        <!-- <a tabindex="0" target="_blank" class="cursor-pointer" href="https://www.imdb.com/">
            <img src="assets/images/imdb.png" width="24" height="24" class="block">
        </a>
        <a tabindex="0" target="_blank" class="cursor-pointer" href="https://www.imdbpro.com/">
            <img src="assets/images/imdbpro.png" width="24" height="24" class="block">
        </a>
        <a tabindex="0" target="_blank" class="cursor-pointer" href="https://www.youtube.com/">
            <img src="assets/images/social/youtube.png" width="24" height="24" class="block">
        </a>
        <a tabindex="0" target="_blank" class="cursor-pointer" href="https://www.tiktok.com/about">
            <img src="assets/images/tiktok.png" width="24" height="24" class="block">
        </a>
        <a tabindex="0" target="_blank" class="cursor-pointer" href=" https://actorsaccess.com">
            <img src="assets/images/social/actorsAccess02.png" width="24" height="24" class="block">
        </a>
        <a tabindex="0" target="_blank" class="cursor-pointer" href="https://www.tiktok.com/about">
            <img src="assets/images/social/castingnetworks.png" width="24" height="24" class="block">
        </a>
        <a tabindex="0" target="_blank" class="cursor-pointer" href="https://www.spotlight.com">
            <img src="assets/images/social/spotlight.png" width="24" height="24" class="block">
        </a>
        <a tabindex="0" target="_blank" class="cursor-pointer" href="https://castingfrontier.com">
            <img src="assets/images/social/casting_frontier.png" width="24" height="24" class="block">
        </a>
        <a tabindex="0" target="_blank" class="cursor-pointer" href=" https://www.nycastings.com">
            <img src="assets/images/social/nycastings.png" width="24" height="24" class="block">
        </a>
        <a tabindex="0" target="_blank" class="cursor-pointer" href="https://www.apple.com/apple-podcasts/">
            <img src="assets/images/social/apple_podcasts.png" width="24" height="24" class="block">
        </a>
        <a tabindex="0" target="_blank" class="cursor-pointer" href="https://open.spotify.com">
            <img src="assets/images/social/spotify.png" width="24" height="24" class="block">
        </a>
        <a tabindex="0" target="_blank" class="cursor-pointer" href="https://www.threads.net">
            <img src="assets/images/social/threads.png" width="24" height="24" class="block">
        </a>
        <a tabindex="0" target="_blank" class="cursor-pointer" href="https://showcast.com.au">
            <img src="assets/images/social/showcast.png" width="24" height="24" class="block">
        </a>
        <a tabindex="0" target="_blank" class="cursor-pointer" href=" https://www.workbook.com">
            <img src="assets/images/workbook.png" width="24" height="24" class="block">
        </a>
        <a tabindex="0" target="_blank" class="cursor-pointer" href="https://encast.eu">
            <img src="assets/images/social/enCast.png" width="24" height="24" class="block">
        </a>
        <a tabindex="0" target="_blank" class="cursor-pointer" href="https://music.amazon.com/podcasts">
            <img src="assets/images/social/amazonmusic.png" width="24" height="24" class="block">
        </a>
        <a tabindex="0" target="_blank" class="cursor-pointer" href="https://castbox.fm">
            <img src="assets/images/social/castbox.png" width="24" height="24" class="block">
        </a> -->
    </div>
</div>
<div class="surface-section px-4 py-2 lg:px-6 surface-border border-top-1 text-center lg:text-left text-600">
    Crewing Up Globally, LLC and Crew Resumes, LLC &copy; 2022
</div>
<!-- msg alert -->
<p-toast position="top-right"></p-toast>