import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { SeoService } from 'src/app/seo.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss']
})
export class DisclaimerComponent implements OnInit {
  url = "https://app.termly.io/embed-policy.min.js";
  
  /**
    * @constructor
    * @param {SeoService} seo - The seo service for call API.
    * @param {Router} router - For getting router details.
  */
  constructor(
    private seo: SeoService,
    private router:Router,
    @Inject(PLATFORM_ID) private platformId: Object
  ) { }

  ngOnInit(): void {
    this.loadScript();
    this.updateTags()
  }
  
  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
    }
  }
  //update the tags
   updateTags(){
    this.seo.updateCanonicalUrl(environment.baseUrl + this.router.url)
    this.seo.updateTitle('CrewResumes - Disclaimer')
    this.seo.updateTags([
      { name: 'description', content: 'The largest media and entertainment cast and crew database in the world, developed by industry professionals who have experienced a need to prep, find, replace, or hire talent.' },
      { property: 'og:url', content: environment.baseUrl + this.router.url},
      { property: 'og:title', content: 'CrewResumes - Disclaimer' },
      { property: 'og:description', content: 'The largest media and entertainment cast and crew database in the world, developed by industry professionals who have experienced a need to prep, find, replace, or hire talent.' },
      { property: 'og:image', content: environment.logoUrl },
    ]);
  }
  //load the script 
  public loadScript() {
    let node = document.createElement("script");
    node.src = this.url;
    node.type = "text/javascript";
    node.async = true;
    node.charset = "utf-8";
    document.getElementsByTagName("head")[0].appendChild(node);
}

}
