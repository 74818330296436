import { Component, OnInit, Inject, PLATFORM_ID,ViewChild } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { CommonService } from '../common.service';
import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { Location } from '@angular/common';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment'
import { Router } from '@angular/router';
import { SeoService } from 'src/app/seo.service';

interface City {
  name: string,
  code: string
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers:[MessageService]
})
export class HomeComponent implements OnInit {
  selectedCity: any;
  visibleMember?: number;
  countryNames: string = '';
  stateName: string = '';
  cityName: string = '';
  latituded: string = '';;
  longituded: string = '';;
  street: string = '';;
  zip: string = '';;
  locationName: string = '';;
  fullAddress: any;
  submitted1: boolean = false;
  display1: boolean = false;
  display2: boolean = false;
  display3: boolean = false;
  display4: boolean = false;
  display5: boolean = false;
  @ViewChild("placesRef") placesRef! : GooglePlaceDirective;
 
  /**
  * @constructor
  * @param {Router} router - For getting router details.
  * @param {SeoService} seo - The userService service for call API.
  */
  constructor(
    private router: Router,
    private seo: SeoService,
    @Inject(PLATFORM_ID) private platformId: Object
    ) { 
  }
  // ngAfterViewInit(){
  //   this.placesRef.options.componentRestrictions = { country: 'USA' }
  //   this.placesRef.options.fields = ["formatted_address", "geometry", "place_id"]
  // }
  ngOnInit(): void {
    this.updateTags()
  }
  //update tag
   updateTags(){
    this.seo.updateCanonicalUrl(environment.baseUrl + this.router.url)
    this.seo.updateTitle('Crew Resumes - Media And Entertainment Cast And Crew Database')
    this.seo.updateTags([
      { name: 'description', content: 'The largest media and entertainment cast and crew database in the world, developed by industry professionals who have experienced a need to prep, find, replace, or hire talent.' },
      { property: 'og:url', content: environment.baseUrl + this.router.url},
      { property: 'og:title', content: 'CrewResumes - Media And Entertainment Cast And Crew Database' },
      { property: 'og:description', content: 'The largest media and entertainment cast and crew database in the world, developed by industry professionals who have experienced a need to prep, find, replace, or hire talent.' },
      { property: 'og:image', content: environment.logoUrl },
    ]);
   }

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
    }
   }
   
  searchCategory(){
  }

  //for navigate the path
  readMoreAboutPam(){
    this.router.navigate(['/about-us'])
  }

  //for navigate the path
  goList(){
    // window.location.href =
    // environment.memberUrl + 'signup';
    this.router.navigate(['/crew-list'])
  }

  //for navigate the path
  signIn(){
    window.location.href =
    environment.memberUrl
  }

  //for dialog box 
  showDialog() {
    this.display1 = true;
  }

  //for dialog box 
  showDialog1() {
    this.display2 = true;
  }

  //for dialog box 
  showDialog2() {
    this.display3 = true;
  }

  //for dialog box 
  showDialog3() {
    this.display4 = true;
  }

  //for dialog box 
  showDialog4() {
    this.display5 = true;
  }
  //Set google address
  public handleAddressChange(address : any) {
    let addDetails = this.extract(address);
    this.fullAddress = addDetails.full_address;
    this.countryNames = addDetails.detail.country.short_name;
    this.stateName = addDetails.detail.state;
    this.cityName = addDetails.detail.city;
    this.latituded = addDetails.latlng.lat;
    this.longituded = addDetails.latlng.lng;
    this.street = addDetails.detail.street;
    this.zip = addDetails.detail.zip;
    this.locationName = addDetails.detail.location;
  }

  //Google address set small part
  public extract(receivedAddresses : any) {
    let address_components = receivedAddresses.address_components;
    let address = {
      full_address: receivedAddresses.formatted_address,
      latlng: {
        lat: receivedAddresses.geometry.location.lat(),
        lng: receivedAddresses.geometry.location.lng(),
      },
      detail: {
        location: '',
        street: '',
        city: '',
        state: '',
        department: '',
        country: {
          name: '',
          short_name: '',
        },
        zip: '',
      },
    };
    address_components.forEach((component : any) => {
      if (component.types.indexOf("street_number") > -1) {
        // set numero
        address.detail['location'] = component.long_name;
      }
      if (component.types.indexOf("route") > -1) {
        // set voie
        address.detail['street'] = component.long_name;
      }
      if (component.types.indexOf("locality") > -1) {
        // set ville
        address.detail['city'] = component.long_name;
      }
      if (component.types.indexOf("administrative_area_level_2") > -1) {
        // set departement
        address.detail['department'] = component.long_name;
      }
      if (component.types.indexOf("administrative_area_level_1") > -1) {
        // set region
        address.detail['state'] = component.long_name;
      }
      if (component.types.indexOf("country") > -1) {
        // set pays

        address.detail['country'] = {
          name: component.long_name,
          short_name: component.short_name,
        }
      }
      if (component.types.indexOf("postal_code") > -1) {
        // set code postal
        address.detail['zip'] = component.long_name;
      }
    });
    return address;
  }

}
