<div class="surface-ground">
    <div class="grid grid-nogutter">
        <div class="col-12 md:col-6 bg-no-repeat bg-cover p-4 md:p-8"
            style="background-image: url('assets/images/contact/contact-2.jpg')">
            <div class="text-white text-2xl  mb-6">Contact Us</div>
            <!-- address of the company -->
            <!-- <div class="text-gray-300 line-height-3 mb-6">3950 Laurel Canyon Boulevard #1250<br>Studio City, California
                91604</div>
            <a href="https://www.google.com/maps?q=3950+Laurel+Canyon+Boulevard+#1250+Studio+City,+California+91604"
                target="_blank"
                class="inline-flex align-items-center text-blue-300 font-bold no-underline cursor-pointer">
                <span class="mr-3">View Address on Google Maps</span>
                <i class="pi pi-arrow-right"></i>
            </a> -->
            <div class="text-gray-300 line-height-3 mb-6">Feel free to contact us with any questions or for further
                assistance. For more information, visit the "How It Works" and "Frequently Asked Questions" sections in
                the footer. We look forward to assisting you with any inquiries.</div>

            <ul class="list-none p-0 m-0 mt-6 text-white">
                <!-- <li class="flex align-items-center mb-3">
                    <i class="pi pi-phone mr-2"></i>
                    <span>+123456789</span>
                </li> -->
                <!-- <li class="flex align-items-center mb-3">
                    <i class="pi pi-twitter mr-2"></i>
                    <img src="assets/images/social/x.png" width="24" height="24" class="block mr-2">
                    <span>@crew_resumes</span>
                </li> -->
                <li class="flex align-items-center mb-3">
                    <i class="pi pi-inbox mr-2"></i>
                    <span>pam@crewresumes.com</span>
                </li>
                <!-- <li class="flex align-items-center mb-3">
                    <i class="pi pi-mobile mr-2"></i>
                    <a href="tel:+1 818-732-1189" style="text-decoration: none;" class="text-white">+1 818-732-1189</a>
                </li> -->
            </ul>
        </div>

        <div class="col-12 md:col-6">
            <!-- [formGroup]="contactUs" used -->
            <form [formGroup]="contactUs">
                <div class="p-fluid formgrid grid px-4 py-8 md:px-6 lg:px-8 grid-nogutter">
                    <div class="field col-12 lg:col-6 p-float-label mb-4 lg:pr-2">
                        <input id="firstname" type="text" class="py-3 px-2 text-lg" autocomplete="off"
                            formControlName="firstName" [ngClass]="{ 'ng-dirty': submitted1 && f['firstName'].errors }"
                            pInputText placeholder="First Name">
                        <div *ngIf="submitted1 && f['firstName'].errors" class="invalid-feedback">
                            <small *ngIf="f['firstName'].errors['required']" class="p-error block">First name is
                                required</small>

                        </div>
                    </div>
                    <div class="field col-12 lg:col-6 p-float-label mb-4 lg:pl-2">
                        <input id="lastName" type="text" class="py-3 px-2 text-lg" autocomplete="off"
                            formControlName="lastName" [ngClass]="{ 'ng-dirty': submitted1 && f['lastName'].errors }"
                            pInputText placeholder="Last Name">
                        <div *ngIf="submitted1 && f['lastName'].errors" class="invalid-feedback">
                            <small *ngIf="f['lastName'].errors['required']" class="p-error block">Last name is
                                required</small>
                        </div>
                    </div>
                    <div class="field col-12 mb-4">
                        <input id="email" type="text" formControlName="email" autocomplete="off"
                            [ngClass]="{ 'ng-dirty': submitted1 && f['email'].errors }" pInputText
                            class="py-3 px-2 text-lg" placeholder="Email">
                        <div *ngIf="submitted1 && f['email'].errors" class="invalid-feedback">
                            <small *ngIf="f['email'].errors['required']" class="p-error block">Email is required</small>

                        </div>
                    </div>
                    <div class="field col-12 mb-4">
                        <input id="phone" type="text" formControlName="phone" autocomplete="off"
                            [ngClass]="{ 'ng-dirty': submitted1 && f['phone'].errors }" pInputText
                            class="py-3 px-2 text-lg" placeholder="Phone">
                        <div *ngIf="submitted1 && f['phone'].errors" class="invalid-feedback">
                            <small *ngIf="f['phone'].errors['required']" class="p-error block">Phone is required</small>

                        </div>
                    </div>
                    <div class="field col-12 mb-4">
                        <textarea id="message" formControlName="message" autocomplete="off"
                            [ngClass]="{ 'ng-dirty': submitted1 && f['message'].errors }" pInputTextarea rows="3"
                            [autoResize]="true" class="py-3 px-2 text-lg" placeholder="Message"></textarea>
                        <div *ngIf="submitted1 && f['message'].errors" class="invalid-feedback">
                            <small *ngIf="f['message'].errors['required']" class="p-error block">Messageis
                                required</small>

                        </div>
                    </div>
                    <!-- submit the form on click submit -->
                    <div class="col-12 text-right">
                        <button type="button" pButton pRipple label="Submit" (click)="submit()" icon="pi pi-envelope"
                            class="px-5 py-3 w-auto p-button-primary"></button>
                    </div>
                </div>
            </form>
        </div>

    </div>
</div>
<!-- for alert msg -->
<p-toast position="top-right"></p-toast>