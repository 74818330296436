import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpBackend } from '@angular/common/http';
import { Router } from '@angular/router';
import {environment } from 'src/environments/environment'
import { AuthGuard } from 'src/app/core/guards/auth.guard';
import {Observable, delay} from 'rxjs';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  observer = new Subject();
  public subscriber$ = this.observer.asObservable();

  
  /**
  * @constructor
  * @param {HttpClient} http - Communicating with backend services using HTTP.
  * @param {Router} router - For getting router details.
  */
  constructor(
    private http:HttpClient,
    private router:Router
  ) { }
  //emit data
  emitData(data: any) {
    this.observer.next(data);
  }
  //get router url
  getRouter(){
    return this.router.url
  }
  //get input fields 
  getFiels(){
    return this.http.get(environment.apiUrl+'fields');
  }
  //get Cities data
  getCities(state: any,country : any){
    return this.http.get(environment.apiUrl+`cities?country=${country}&state=${state}`);
  }
  //get Countries data
  getCountries(){
    return this.http.get(environment.apiUrl+'countries');
  }
  //get States data
  getStates(country : any){
    return this.http.get(environment.apiUrl+`states?country=${country}`);
  }
  //get Crew List
  getCrewList(filter:any){
    return this.http.get(environment.apiUrl+`crews${filter}`);
  }
  //getCategory data
  getCategory(){
    return this.http.get(environment.apiUrl+'categories/active');
  }
  //get SubCategory List
  getSubCategoryList(id:any){
    return this.http.get(environment.apiUrl+`categories/active?parentId=${id}`);
  }
  //get profile data
  getProfileDetailsId(id:any){
    return this.http.get(environment.apiUrl+`crews/profile/${id}/view`);
  }
   //get profile by username
   getProfileDetailsUserName(userName:any): Observable<any>{
    return this.http.get(environment.apiUrl+`crews/profile/username/${userName}/view`);
  }
  //get media data
  getMediaDetailsId(id:any){
    return this.http.get(environment.apiUrl+`usermedias/user/${id}`);
  }
  
}
