import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  /**
  * @constructor
  * @param {HttpClient} http - Communicating with backend services using HTTP.
  */
  constructor(
    private http: HttpClient
  ) { }
  //for CategoryList.
  getCategoryList() {
    return this.http.get(environment.apiUrl + 'categories/active');
  }
  //for linklist.
  getlinkList() {
    return this.http.get(environment.apiUrl + 'links/active');
  }
  //for resourcelist.
  getResourceList() {
    return this.http.get(environment.apiUrl + 'resources/active');
  }
  //for contact.
  contact(data: any) {
    return this.http.post(environment.apiUrl + `contactus`, data)
  }
  //for send msg from footer.
  sendNewsLetter(userData: any) {
    return this.http.post(environment.apiUrl + 'newsletters', userData);
  }
}
