import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  Resolve,
  ActivatedRoute,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router
} from '@angular/router';
import { of, Observable } from 'rxjs';
import { UsersService } from './users.service';
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { tap, first, catchError } from 'rxjs/operators';

import { isPlatformServer } from '@angular/common';
import {
  makeStateKey,
  TransferState,
  Title,
  Meta,
} from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class UserResolve implements Resolve<any> {
  constructor(
    private http: HttpClient,
    private transferstate: TransferState,
    private messageService: MessageService,
    @Inject(PLATFORM_ID) private platformId: any,
    private router: Router,

  ) {}
  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any> {
    // const { userName } = route.params;
    const userName = route.params['userName'];
    const id = route.params['id'];
    console.log('Username ', userName);
    console.log('id ', id);
    let url = userName != undefined ? `crews/profile/username/${userName}/view` : `crews/profile/${id}/view`;
    let metaKey = userName != undefined ? userName : id
    const META_DATA_KEY = makeStateKey<any>(`USER_DATA_key_${metaKey}`);
    if (this.transferstate.hasKey(META_DATA_KEY)) {
      const userData = this.transferstate.get<any>(META_DATA_KEY, null);
      this.transferstate.remove(META_DATA_KEY);
      return of(userData);
    } else {
      return this.http
        .get(
          environment.apiUrl + url
        )
        // .pipe(
        //   first(),
        //   tap((userData : any) => {
        //     if (isPlatformServer(this.platformId)) {
        //       this.transferstate.set(META_DATA_KEY, userData);
        //     }
        //   }, (error: any) => {
        //     this.messageService.add({ severity: 'error', 
        //     summary: 'Error', 
        //     detail: error.error.error.message });
        // }));

        .pipe(
          tap((userData : any) => { 
            if(isPlatformServer(this.platformId)){
              this.transferstate.set(META_DATA_KEY, userData);
            }
           }),
          catchError((error) => {
            this.router.navigate(["/404"], {skipLocationChange: true})
            return of(error);
          }),
        );
    }
  }
}
