<div class="surface-overlay py-2 px-4 md:px-6 shadow-2 flex align-items-center justify-content-between relative lg:static" style="min-height: 80px">
    
    <img src="assets/logo/logo.png" alt="Image" height="60" class="mr-0 lg:mr-6 cursor-pointer" (click)="home()">
    <a pRipple class="cursor-pointer block lg:hidden" pStyleClass="@next" enterClass="hidden" leaveToClass="hidden" [hideOnOutsideClick]="true">
        <i class="pi pi-bars text-2xl"></i>
    </a>
    <!-- for global search -->
    <div class="align-items-center flex-grow-1 justify-content-end hidden lg:flex absolute lg:static w-full surface-overlay left-0 top-100 z-1 shadow-2 lg:shadow-none">
        <ul class="list-none p-0 m-0 flex lg:align-items-center select-none flex-column lg:flex-row">
            <li class="flex-order-2 lg:flex-order-0 hidden lg:flex">
                <div class="flex px-4 p-3 lg:px-3 lg:py-2">
                    <span style="position: relative;" class="p-input-icon-left w-full">
                        <i class="pi pi-search text-white-400"></i>
                        <input type="text" autocomplete="off" (keyup.enter)="onSearch($event)" [(ngModel)]="keyWord"  pInputText placeholder="Search" class="bg-white-800 text-white-400 border-white-800 w-full" style="padding-right: 30px;"/>
                        <i class="pi pi-times" style="position: absolute;right: 10px;" *ngIf="cross" (click)="inputClear()"></i>
                    </span>
                </div>
            </li>
             <!-- navigate to other page on click How It Works -->
            <div>
                <li class="lg:relative">
                    <a pRipple class="flex px-4 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100  border-round cursor-pointer transition-colors transition-duration-150">
                        <span routerLink="/how-it-works">How It Works</span>
                    </a>
                </li>
                <!-- navigate to other page on click goList -->
                <li class="lg:hidden">
                    <div class="flex px-4 p-3 lg:px-3 lg:py-2">
                        <button (click)="goList()" pButton pRipple label="Search Cast And Crew" class="p-button-outlined"></button>
                    </div>
                </li>
                <!-- <li>
                    <a pRipple class="flex px-4 p-3 lg:px-3 lg:py-2 align-items-center text-600 hover:text-900 hover:surface-100  border-round cursor-pointer transition-colors transition-duration-150">
                       
                        <span  routerLink="/">Login</span>
                    </a>
                </li> -->
            </div>
        </ul>
        <!-- navigate to other page on click gotoProfile -->
        <ul class="list-none p-0 m-0 lg:ml-3 flex lg:align-items-center select-none flex-column lg:flex-row border-top-1 surface-border lg:border-top-none">
            <li class="border-top-1 surface-border lg:border-top-none px-4 py-3 lg:px-0 lg:py-0">
                <button pButton label="My Profile" (click)="gotoProfile()" class="p-button-outlined" icon="pi pi-arrow-right" iconPos="right"></button>
                <!-- <a pRipple class="flex px-6 p-3 lg:pl-3 lg:pr-0 lg:py-2 align-items-center hover:surface-100  border-round cursor-pointer transition-colors transition-duration-150">
                    <img src="assets/images/avatar-f-1.png" class="mr-3 lg:mr-0" style="width: 40px; height: 40px"/>
                    <div class="block lg:hidden">
                        <div class="text-900 ">Josephine Lillard</div>
                        <span class="text-600  text-sm">Marketing Specialist</span>
                    </div>
                </a> -->
            </li>
        </ul>
    </div>
</div>